import { createContext, useCallback, useEffect, useState } from 'react'

import axios from 'axios'
import firebase from 'firebase/app'

import { useLocalStorage } from '@sergeimeza/foundation-react'
import {
  useAuth,
  useSignInAnonymously,
  useSignInWithCustomToken,
} from '@sergeimeza/firebase-react'

import {
  Bending,
  FitnessTest,
  Grip,
  HandballThrow,
  ShuttleRun,
  SideJump,
  SitUps,
  SprintRun,
  StandingJump,
} from '../utils/FitnessTest'
import { InitializeFirebase } from '../services/firebase'
import { TochigiResults } from '../utils/TochigiResults'

const baseURL = process.env.REACT_APP_TOCHIGI_API_URL!

export let TochigiAPI = axios.create({
  baseURL,
})

interface TochigiValue {
  user: any
  basicInfo: any
  testResults: any
  userCredential: any
  loginSession: any
  isUsernameAvailable: (username: string) => Promise<boolean>
  signIn: () => any
  signOut: () => Promise<any>
  deleteUser: () => Promise<any>
  signInWithUsernamePassword: (
    username: string,
    password: string,
  ) => Promise<any>
  linkWithUsernamePassword: (
    username: string,
    password: string,
    birthday: string,
  ) => Promise<any>
  changePassword: (
    username: string,
    password: string,
    birthday: string,
  ) => Promise<any>
  saveLoginSession: (payload: any) => any
  resetLoginSession: (payload: any) => any
  loadTestResults: (id: string) => Promise<any>
  deleteTestResults: (id: string) => Promise<any>
  saveBasicInfo: (info: any) => Promise<any>
  saveTestResults: (results: any) => Promise<any>
}

const initialState: TochigiValue = {
  user: null,
  basicInfo: null,
  testResults: null,
  userCredential: null,
  loginSession: null,
  deleteUser: async () => {},
  isUsernameAvailable: async () => false,
  signIn: () => {},
  signOut: async () => {},
  signInWithUsernamePassword: async () => {},
  linkWithUsernamePassword: async () => {},
  changePassword: async () => {},
  saveLoginSession: () => {},
  resetLoginSession: () => {},
  loadTestResults: async () => {},
  deleteTestResults: async () => {},
  saveBasicInfo: async () => {},
  saveTestResults: async () => {},
}

export const TochigiContext = createContext<TochigiValue>(initialState)

const { auth } = InitializeFirebase()

export const TochigiProvider = ({ children }) => {
  const [userCredential, authLoading, authError] = useAuth(auth)
  const [signInAnonymously, anonymousUserCredential] =
    useSignInAnonymously(auth)

  const [signInWithCustomToken, tokenCredential, tokenLoading, tokenError] =
    useSignInWithCustomToken(auth)

  const [loginSession, saveLoginSession, resetLoginSession] = useLocalStorage(
    'LOGIN_SESSION',
    null,
  )
  const [basicInfo, setBasicInfo, resetBasicInfo] = useLocalStorage(
    'BASIC_INFO',
    null,
  )
  const [user, setUser, resetUser] = useLocalStorage('USER', null)
  const [testResults, setTestResults] = useState(initialState.testResults)

  // logs
  useEffect(() => {
    console.log('authError', authError)
  }, [authError])
  // logs
  useEffect(() => {
    console.log('tokenError', tokenError)
  }, [tokenError])
  // anonymousUserCredential
  useEffect(() => {
    let user = anonymousUserCredential?.user
    if (!user) return
    getUser(user)
  }, [anonymousUserCredential?.user])

  const getUser = useCallback(
    async (user: firebase.User) => {
      const result = await TochigiAPI.post('/auth', {
        firebaseId: user.uid,
      })
      if (result.data) {
        console.log()
        let token = result.data.data
        token = `Bearer ${token}`
        console.log('🚀 token', token)
        TochigiAPI = axios.create({
          baseURL,
          headers: {
            'x-auth-token': token,
          },
        })
        console.log('🚀 setting TochigiAPI')

        let userResult: any = await TochigiAPI.get('/user').catch(() =>
          console.error('🚀 SERVER_ERROR'),
        )

        if (userResult.data) {
          setUser(userResult.data.data)
        }
      }
    },
    [setUser],
  )

  const signIn = useCallback(() => {
    console.log('🚀 signIn')
    if (userCredential) {
      console.log('signIn.getUser')
      getUser(userCredential)
    } else if (loginSession) {
      console.log('signIn.linkWithUsernamePassword')
      signInWithUsernamePassword(loginSession.username, loginSession.password)
    } else {
      console.log('signIn.signInAnonymously')
      signInAnonymously()
    }
  }, [])

  useEffect(() => {
    if (authLoading) {
      console.log('authLoading', authLoading)
      return
    }
    if (authError) {
      console.error('🚀 auth.error', authError.message)
      return
    }
    console.log('authLoading', authLoading)
    signIn()
  }, [authLoading])

  const signOut = useCallback(async () => {
    try {
      await auth.signOut()
      resetLoginSession()
      resetBasicInfo()
      resetUser()
      TochigiAPI = axios.create({
        baseURL,
      })
      await signInAnonymously()
    } catch (err) {
      console.log(err)
    }
  }, [])

  // sign in
  const signInWithUsernamePassword = async (
    username: string,
    password: string,
  ) => {
    let response = await TochigiAPI.post('/sign-in-username-password', {
      username,
      password,
    })
    if (response.data) {
      console.log('signInWithUsernamePassword', response.data)
      let token = response.data.data
      let error = response.data.error
      if (token) {
        await signInWithCustomToken(token)
        return true
      } else {
        console.log('error', error)
        return false
      }
    }
    return false
  }

  // create account
  async function linkWithUsernamePassword(
    username: string,
    password: string,
    birthday: string,
  ) {
    let response = await TochigiAPI.put('/sign-in-username-password', {
      username,
      password,
      birthday,
    })
    if (response.data) {
      console.log('linkWithUsernamePassword', response.data)
      let token = response.data.data
      let error = response.data.error
      if (token) {
        // await signInWithCustomToken(token)
        return true
      } else {
        console.log('error', error)
        return false
      }
    }
    return false
  }

  // change password
  async function changePassword(
    username: string,
    password: string,
    birthday: string,
  ) {
    let response = await TochigiAPI.post('/change-password', {
      username,
      password,
      birthday,
    })
    if (response.data) {
      console.log('linkWithUsernamePassword', response.data)
      let sucess = response.data.data
      let error = response.data.error
      if (sucess) {
        return true
      } else {
        console.log('error', error)
        return false
      }
    }
    return false
  }

  // delete user
  async function deleteUser() {
    await TochigiAPI.delete(`/user`)
  }

  useEffect(() => {
    if (!tokenCredential) return
    if (tokenCredential?.user) {
      getUser(tokenCredential.user)
    }
  }, [tokenCredential])

  useEffect(() => {
    if (anonymousUserCredential?.user) {
      console.log('anonymousUserCredential.getUser')
      getUser(anonymousUserCredential.user)
    }
  }, [anonymousUserCredential?.user])

  async function saveBasicInfo(info) {
    let height = info.height
    height = height === 0 ? undefined : height
    let weight = info.weight
    weight = weight === 0 ? undefined : weight
    let bi = {
      firebaseId: userCredential?.uid,
      name: info.name,
      birthday: info.birthday,
      gender: info.gender,
      height,
      weight,
      age: isNaN(+info.schoolGrade) ? undefined : +info.schoolGrade,
      schoolType: info.schoolType,
    }

    setBasicInfo(bi)
    let userResponse = await TochigiAPI.post('/user', {
      attributes: {
        ...loginSession,
        ...bi,
      },
    })
    if (userResponse.data) {
      return userResponse.data.data
    }
  }
  async function isUsernameAvailable(username: string) {
    const response = await TochigiAPI.get('/username-available', {
      params: { username },
    })
    return response.data
  }

  async function saveTestResults(results: any) {
    let _results: any[] = []

    let gender = basicInfo.gender ?? 'MALE'
    let age = basicInfo.age

    let genderNumber = gender === 'MALE' ? 1 : 2
    let prefAverage = TochigiResults.find(
      (r) => r.age === age && r.gender === genderNumber,
    )

    let testDate = new Date(results.testDate)
    let testYear = testDate.getFullYear()

    if (results.grip) {
      let value = results.grip
      let score = Grip.computePoints(value, age, gender)
      let rating = Grip.getRating(value, age, gender)
      let comment = Grip.getComment(rating, age)
      _results.push({
        key: 'grip',
        name: '握力（あくりょく）',
        result: `${value} kg`,
        score,
        average: `${prefAverage?.grip_avg.toFixed(2)} kg`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'grip',
        name: '握力（あくりょく）',
      })
    }
    if (results.sitUps) {
      let value = results.sitUps
      let score = SitUps.computePoints(value, age, gender)
      let rating = SitUps.getRating(value, age, gender)
      let comment = SitUps.getComment(rating, age)
      _results.push({
        key: 'sitUps',
        name: '上体起こし（じょうたいおこし）',
        result: `${value} 回`,
        score,
        average: `${prefAverage?.sit_ups_avg.toFixed(2)} 回`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'sitUps',
        name: '上体起こし（じょうたいおこし）',
      })
    }
    if (results.bending) {
      let value = results.bending
      let score = Bending.computePoints(value, age, gender)
      let rating = Bending.getRating(value, age, gender)
      let comment = Bending.getComment(rating, age)
      _results.push({
        key: 'bending',
        name: '長座体前屈（ちょうざたいぜんくつ）',
        result: `${value} cm`,
        score,
        average: `${prefAverage?.bending_avg.toFixed(2)} cm`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'bending',
        name: '長座体前屈（ちょうざたいぜんくつ）',
      })
    }
    if (results.sideJump) {
      let value = results.sideJump
      let score = SideJump.computePoints(value, age, gender)
      let rating = SideJump.getRating(value, age, gender)
      let comment = SideJump.getComment(rating, age)
      _results.push({
        key: 'sideJump',
        name: '反復横とび（はんぷくよことび）',
        result: `${value} 点`,
        score,
        average: `${prefAverage?.side_jump_avg.toFixed(2)} 点`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'sideJump',
        name: '反復横とび（はんぷくよことび）',
      })
    }
    if (results.shuttleRun) {
      let value = results.shuttleRun
      let score = ShuttleRun.computePoints(value, age, gender)
      let rating = ShuttleRun.getRating(value, age, gender)
      let comment = ShuttleRun.getComment(rating, age)
      _results.push({
        key: 'shuttleRun',
        name: '20mシャトルラン',
        result: `${value} 回`,
        score,
        average: `${prefAverage?.shuttle_run_avg.toFixed(2)} 回`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'shuttleRun',
        name: '20mシャトルラン',
      })
    }
    if (results.sprintRun) {
      let value = results.sprintRun
      let score = SprintRun.computePoints(value, age, gender)
      let rating = SprintRun.getRating(value, age, gender)
      let comment = SprintRun.getComment(rating, age)
      _results.push({
        key: 'sprintRun',
        name: '50m走（50mそう）',
        result: `${value} 秒`,
        score,
        average: `${prefAverage?.sprint_run_avg.toFixed(2)} 秒`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'sprintRun',
        name: '50m走（50mそう）',
      })
    }
    if (results.standingJump) {
      let value = results.standingJump
      let score = StandingJump.computePoints(value, age, gender)
      let rating = StandingJump.getRating(value, age, gender)
      let comment = StandingJump.getComment(rating, age)
      _results.push({
        key: 'standingJump',
        name: '立ち幅とび（たちはばとび）',
        result: `${value} cm`,
        score,
        average: `${prefAverage?.standing_jump_avg.toFixed(2)} cm`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'standingJump',
        name: '立ち幅とび（たちはばとび）',
      })
    }
    if (results.handballThrow) {
      let value = results.handballThrow
      let score = HandballThrow.computePoints(value, age, gender)
      let rating = HandballThrow.getRating(value, age, gender)
      let comment = HandballThrow.getComment(rating, age)
      _results.push({
        key: 'handballThrow',
        name: 'ボール投げ（ボールなげ）',
        result: `${value} m`,
        score,
        average: `${prefAverage?.ball_throw_avg.toFixed(2)} m`,
        rating,
        comment,
        ranking: null,
      })
    } else {
      _results.push({
        key: 'handballThrow',
        name: 'ボール投げ（ボールなげ）',
      })
    }

    let points = _results
      .map((item) => item.score)
      .reduce(
        (prev, curr) => (isNaN(prev) ? 0 : prev) + (isNaN(curr) ? 0 : curr),
      )

    let grade = FitnessTest.computeGrade(points, age)

    let pointsUntilNextGrade
    let nextGrade
    if (grade !== 'A') {
      pointsUntilNextGrade = FitnessTest.computePointsUntilNextGrade(
        points,
        age,
      )

      nextGrade =
        grade === 'E'
          ? 'D'
          : grade === 'D'
          ? 'C'
          : grade === 'C'
          ? 'B'
          : grade === 'B'
          ? 'A'
          : undefined
    }

    let testResult = await TochigiAPI.post('/tests', {
      data: {
        testYear,
        ...results,
        ...basicInfo,
        testDate,
        results: _results,
        points,
        grade,
        pointsUntilNextGrade,
        nextGrade,
      },
    })

    if (testResult.data) {
      await getUser(userCredential)

      setTestResults(testResult.data.data)
      return testResult.data.data
    }
  }

  const loadTestResults = async (id: string) => {
    let testResult = await TochigiAPI.get(`/tests/${id}`)
    if (testResult.data) {
      let { test, leaderboard } = testResult.data.data
      test.results = test.results.map((item) => ({
        ...item,
        ranking: `${
          leaderboard.find(
            (leaderboardItem) => leaderboardItem.key === item.key,
          ).rank + 1
        }位 / ${
          leaderboard.find(
            (leaderboardItem) => leaderboardItem.key === item.key,
          ).count
        }人`,
      }))
      setTestResults({ test, leaderboard })
    }
  }

  const deleteTestResults = async (id: string) => {
    await TochigiAPI.delete(`/tests/${id}`)
    return true
  }

  const value: TochigiValue = {
    user,
    basicInfo,
    testResults,
    userCredential,
    loginSession,
    saveLoginSession,
    resetLoginSession,
    deleteUser,
    isUsernameAvailable,
    signIn,
    signOut,
    signInWithUsernamePassword,
    linkWithUsernamePassword,
    changePassword,
    loadTestResults,
    deleteTestResults,
    saveBasicInfo,
    saveTestResults,
  }

  return (
    <TochigiContext.Provider value={value}>{children}</TochigiContext.Provider>
  )
}
