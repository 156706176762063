import { useCallback, useContext, useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import {
  StepsNavigation,
  Input,
  Button,
  SelectMenu,
  Badge,
  Table,
} from '@sergeimeza/uikit-react'
import {
  StepNavigationStatus,
  StepsNavigationItem,
} from '@sergeimeza/uikit-react/lib/Elements/Navigation/NavigationItem'

import { useForm, useWatch } from 'react-hook-form'

import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import { message } from 'antd'

import { TochigiLayout } from '../layout/TochigiLayout'
import { useQuery } from '../../hooks/useQuery'
import { TochigiContext } from '../../providers/TochigiProvider'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'
import { schoolGrades } from '../../utils'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'
import { currentYear } from '../../utils/constants'

let basicInfoSchema = yup.object().shape({
  name: yup.string().max(100).optional(),
  schoolType: yup.string().required(),
  schoolGrade: yup.number().required(),
  gender: yup.string().required(),
  height: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  weight: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
})

let testResultSchema = yup.object().shape({
  testDate: yup.string().optional(),
  grip: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  sitUps: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  bending: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  sideJump: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  shuttleRun: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  sprintRun: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  standingJump: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
  handballThrow: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value))
    .nullable(),
})

export const FitnessPage: React.FC<any> = () => {
  let query = useQuery()
  const history = useHistory()
  const layoutRef = useRef<any>(null)

  const tochigiContext = useContext(TochigiContext)

  const [showConfirmationSection, setShowConfirmationSection] = useState(false)

  const [fitnessTestSteps, setFitnessTestSteps] = useState<
    StepsNavigationItem[]
  >([
    {
      id: '01',
      name: '基本情報を入力',
      href: '/fitness?step=info',
      status: StepNavigationStatus.current,
    },
    {
      id: '02',
      name: '体力結果を入力',
      href: '#',
      status: StepNavigationStatus.upcoming,
    },
  ])

  const [profileSubmitted, setProfileSubmitted] = useState(false)

  const {
    register: registerbasicInfo,
    control: registerBasicInfoControl,
    handleSubmit: handlebasicInfoSubmit,
    formState: { errors: basicInfoErrors },
    reset: resetBasicInfo,
    setValue: setValueForm
  } = useForm<any>({
    defaultValues: {
      // birthday: '2014-01-01',
      // height: 0,
      // weight: 0,
    },
    resolver: yupResolver(basicInfoSchema),
  })

  const {
    register: registerTestResult,
    handleSubmit: handleTestResultSubmit,
    formState: { errors: testResultErrors },
    reset: resetTestResult,
  } = useForm<any>({
    defaultValues: {
      testDate: `${currentYear}-04-01`,
      // grip: 0,
      // sitUps: 0,
      // bending: 0,
      // sideJump: 0,
      // shuttleRun: 0,
      // sprintRun: 0,
      // standingJump: 0,
      // handballThrow: 0,
    },
    resolver: yupResolver(testResultSchema),
  })

  const schoolType = useWatch({
    control: registerBasicInfoControl,
    name: 'schoolType',
    defaultValue: 'ELEMENTARY_SCHOOL',
  })

  useEffect(() => {
    setValueForm(
      'schoolGrade',
      schoolType === 'MIDDLE_SCHOOL'
        ? 12
        : schoolType === 'HIGH_SCHOOL' ? 15 : 6
    )
  }, [schoolType, setValueForm])

  const onLoad = useCallback(() => {
    const step = query.get('step')

    if (!step) {
      history.push('/fitness?step=info')
    }

    if (step === 'test') {
      setFitnessTestSteps([
        {
          id: '01',
          name: '基本情報を入力',
          href: '/fitness?step=info',
          status: StepNavigationStatus.complete,
        },
        {
          id: '02',
          name: '体力結果を入力',
          href: '/fitness?step=test',
          status: StepNavigationStatus.current,
        },
      ])

      setProfileSubmitted(true)
    }
  }, [])

  useEffect(() => {
    onLoad()
  }, [onLoad])

  const onbasicInfoSubmit = async (data: any) => {
    await tochigiContext.saveBasicInfo(data)
    message.success('基本情報を登録しました')
    history.push('/fitness?step=test')
    setFitnessTestSteps([
      {
        id: '01',
        name: '基本情報を入力',
        href: '/fitness?step=info',
        status: StepNavigationStatus.complete,
      },
      {
        id: '02',
        name: '体力結果を入力',
        href: '/fitness?step=test',
        status: StepNavigationStatus.current,
      },
    ])

    setProfileSubmitted(true)

    layoutRef.current.scrollIntoView()
  }

  const onTestSubmit = async (data: any) => {
    await tochigiContext.saveTestResults(data)
    message.success('体力結果を登録しました')
    setShowConfirmationSection(true)

    layoutRef.current.scrollIntoView()
  }

  const fitnessStepsNavigation = (
    <StepsNavigation
      steps={fitnessTestSteps}
      type={StepsNavigation.type.panels}
    />
  )

  const basicInfoForm = (
    <div className="mt-5 md:mt-0 md:col-span-2">
      <form onSubmit={handlebasicInfoSubmit(onbasicInfoSubmit)}>
        <div className="border border-yellow-500 overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <h2 className="font-medium text-gray-900 text-2xl tracking-widest mb-4">
              <ruby>
                基本情報<rt>きほんじょうほう</rt>
              </ruby>
              を
              <ruby>
                入力す<rt>にゅうりょく</rt>
              </ruby>
              る
            </h2>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6 lg:col-span-4">
                <Input
                  key="name"
                  type="text"
                  label={
                    <>
                      <span>
                        <ruby>
                          名前<rt>なまえ</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  placeholder=""
                  autoComplete="name"
                  description=""
                  cornerHint=""
                  register={registerbasicInfo('name')}
                  error={basicInfoErrors.name?.message}
                />
              </div>

              <div className="col-span-6 lg:col-span-4">
                <SelectMenu
                  label={
                    <>
                      <span>
                        <ruby>
                          学校種別選択<rt>がっこうしゅべつせんたく</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  selectClassNames="bg-primary-100 border-0"
                  name="schoolType"
                  autoComplete=""
                  options={[
                    { name: '小学校', value: 'ELEMENTARY_SCHOOL' },
                    { name: '中学校', value: 'MIDDLE_SCHOOL' },
                    { name: '高等学校', value: 'HIGH_SCHOOL' },
                  ]}
                  cornerHint={
                    <Badge
                      shape={Badge.shape.default}
                      size={Badge.size.large}
                      color={'bg-yellow-300' as any}
                    >
                      <span className="px-2 pt-1 text-xs">
                        <ruby>
                          必須<rt>ひっす</rt>
                        </ruby>
                      </span>
                    </Badge>
                  }
                  selectedItem={{ name: '小学校', value: 'ELEMENTARY_SCHOOL' }}
                  register={registerbasicInfo('schoolType')}
                />
              </div>

              <div className="col-span-6 lg:col-span-4">
                <SelectMenu
                  label={
                    <>
                      <span>
                        <ruby>
                          学年<rt>がくねん</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  selectClassNames="bg-primary-100 border-0"
                  name="schoolGrade"
                  options={[
                    ...(schoolType === 'MIDDLE_SCHOOL'
                      ? [
                          { name: '中学校 1年生', value: 12 },
                          { name: '中学校 2年生', value: 13 },
                          { name: '中学校 3年生', value: 14 },
                        ]
                      : []),
                    ...(schoolType === 'HIGH_SCHOOL'
                      ? [
                          { name: '高等学校 1年生', value: 15 },
                          { name: '高等学校 2年生', value: 16 },
                          { name: '高等学校 3年生', value: 17 },
                        ]
                      : []),
                    ...(schoolType === 'ELEMENTARY_SCHOOL'
                      ? [
                          { name: '小学校 1年生', value: 6 },
                          { name: '小学校 2年生', value: 7 },
                          { name: '小学校 3年生', value: 8 },
                          { name: '小学校 4年生', value: 9 },
                          { name: '小学校 5年生', value: 10 },
                          { name: '小学校 6年生', value: 11 },
                        ]
                      : []),
                  ]}
                  cornerHint={
                    <Badge
                      shape={Badge.shape.default}
                      size={Badge.size.large}
                      color={'bg-yellow-300' as any}
                    >
                      <span className="px-2 pt-1 text-xs">
                        <ruby>
                          必須<rt>ひっす</rt>
                        </ruby>
                      </span>
                    </Badge>
                  }
                  selectedItem={{ name: '小学校 1年生', value: 1 }}
                  register={registerbasicInfo('schoolGrade')}
                />
              </div>

              <div className="col-span-6 lg:col-span-4">
                <SelectMenu
                  label={
                    <>
                      <span>
                        <ruby>
                          性別<rt>せいべつ</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  selectClassNames="bg-primary-100 border-0"
                  name="gender"
                  options={[
                    { name: '男子', value: 'MALE' },
                    { name: '女子', value: 'FEMALE' },
                  ]}
                  cornerHint=""
                  selectedItem={{ name: '男性', value: 'MALE' }}
                  register={registerbasicInfo('gender')}
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="height"
                  type="number"
                  step=".1"
                  label={
                    <>
                      <span>
                        <ruby>
                          身長<rt>しんちょう</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  placeholder=""
                  description=""
                  autoComplete="height"
                  cornerHint="（cm）"
                  register={registerbasicInfo('height')}
                  error={
                    basicInfoErrors.height?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="weight"
                  type="number"
                  step=".1"
                  label={
                    <>
                      <span>
                        <ruby>
                          体重<rt>たいじゅう</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  placeholder=""
                  description=""
                  cornerHint="（kg）"
                  autoComplete="weight"
                  register={registerbasicInfo('weight')}
                  error={
                    basicInfoErrors.weight?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <div className="space-x-4">
              <Button
                className="inline-flex justify-center border border-yellow-700"
                type="button"
                size={Button.size.xl}
                buttonType={Button.buttonType.secondary}
                onClick={() => {
                  resetBasicInfo({
                    // birthday: '2014-01-01',
                    schoolType: 'ELEMENTARY_SCHOOL',
                    schoolGrade: 6,
                  })
                }}
              >
                <div>
                  <ruby>
                    入力を<rt>にゅうりょく</rt>
                  </ruby>
                  <ruby>
                    消<rt>け</rt>
                  </ruby>
                  す
                </div>
              </Button>
              <Button
                className="inline-flex justify-center"
                type="submit"
                size={Button.size.xl}
                buttonType={
                  'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
                }
              >
                <div>
                  <ruby>
                    続<rt>つづ</rt>
                    ける
                  </ruby>{' '}
                </div>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  const testForm = (
    <div className="mt-5 md:mt-0 md:col-span-2">
      <form onSubmit={handleTestResultSubmit(onTestSubmit)}>
        <div className="border border-yellow-500 overflow-hidden sm:rounded-md">
          <div className="px-4 py-5 bg-white sm:p-6">
            <h2 className="font-medium text-gray-900 text-2xl tracking-widest mb-4">
              <ruby>
                体力テ<rt>たいりょく</rt>
              </ruby>
              スト
              <ruby>
                結果<rt>けっか</rt>
              </ruby>
              を
              <ruby>
                入力す<rt>にゅうりょく</rt>
              </ruby>
              る
            </h2>
            <div className="grid grid-cols-6 gap-6">
              <div className="col-span-6">
                <Input
                  key="testDate"
                  type="date"
                  label={
                    <>
                      <span>
                        <ruby>
                          測定日<rt>そくていび</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  placeholder=""
                  description=""
                  cornerHint=""
                  register={registerTestResult('testDate')}
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="grip"
                  type="number"
                  label={
                    <>
                      <span>
                        <ruby>
                          握力<rt>あくりょく</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 2 : 7}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 39 : 84}
                  placeholder=""
                  description=""
                  cornerHint="（kg）"
                  autoComplete="grip"
                  register={registerTestResult('grip')}
                  error={
                    testResultErrors.grip?.message && '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="sitUps"
                  type="number"
                  label={
                    <>
                      <span>
                        <ruby>
                          上体起<rt>じょうたいお</rt>
                          こし
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 0 : 0}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 39 : 53}
                  placeholder=""
                  description=""
                  cornerHint="（回）"
                  autoComplete="sitUps"
                  register={registerTestResult('sitUps')}
                  error={
                    testResultErrors.sitUps?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="bending"
                  type="number"
                  label={
                    <>
                      <span>
                        <ruby>
                          長座体前屈<rt>ちょうざたいぜんくつ</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 9 : 11}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 74 : 96}
                  placeholder=""
                  description=""
                  cornerHint="（cm）"
                  autoComplete="bending"
                  register={registerTestResult('bending')}
                  error={
                    testResultErrors.bending?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="sideJump"
                  type="number"
                  label={
                    <>
                      <span>
                        <ruby>
                          反復横<rt>はんぷくよこ</rt>
                          とび
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 8 : 13}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 75 : 95}
                  placeholder=""
                  description=""
                  cornerHint="（点）"
                  autoComplete="sideJump"
                  register={registerTestResult('sideJump')}
                  error={
                    testResultErrors.sideJump?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3 mt-2">
                <Input
                  key="shuttleRun"
                  type="number"
                  label="20mシャトルラン"
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 4 : 7}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 135 : 188}
                  placeholder=""
                  description=""
                  cornerHint="（回）"
                  autoComplete="shuttleRun"
                  register={registerTestResult('shuttleRun')}
                  error={
                    testResultErrors.shuttleRun?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="sprintRun"
                  type="number"
                  label={
                    <>
                      <span>
                        50m
                        <ruby>
                          走<rt>そう</rt>
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  step=".1"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 4.0 : 3.3}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 20 : 17}
                  placeholder=""
                  description=""
                  cornerHint="（秒）"
                  autoComplete="sprintRun"
                  register={registerTestResult('sprintRun')}
                  error={
                    testResultErrors.sprintRun?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="standingJump"
                  type="number"
                  label={
                    <>
                      <span>
                        <ruby>
                          立<rt>た</rt>ち
                        </ruby>
                        <ruby>
                          幅<rt>はば</rt>とび
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 42 : 59}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 288 : 398}
                  placeholder=""
                  description=""
                  cornerHint="（cm）"
                  autoComplete="standingJump"
                  register={registerTestResult('standingJump')}
                  error={
                    testResultErrors.standingJump?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>

              <div className="col-span-6 lg:col-span-3">
                <Input
                  key="handballThrow"
                  type="number"
                  label={
                    <>
                      <span>
                        ボール
                        <ruby>
                          投<rt>な</rt>げ
                        </ruby>
                      </span>
                    </>
                  }
                  inputClassNames="bg-primary-100 border-0"
                  min={schoolType === 'ELEMENTARY_SCHOOL' ? 2 : 4}
                  max={schoolType === 'ELEMENTARY_SCHOOL' ? 60 : 56}
                  placeholder=""
                  description=""
                  cornerHint="（m）"
                  autoComplete="handballThrow"
                  register={registerTestResult('handballThrow')}
                  error={
                    testResultErrors.handballThrow?.message &&
                    '入力を確認してください。'
                  }
                />
              </div>
            </div>
          </div>
          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <div className="space-x-4">
              <Button
                className="inline-flex justify-center border border-yellow-700"
                type="button"
                size={Button.size.xl}
                buttonType={Button.buttonType.secondary}
                onClick={() => {
                  resetTestResult({
                    testDate: `${currentYear}-04-01`,
                  })
                }}
              >
                <div>
                  <ruby>
                    入力を<rt>にゅうりょく</rt>
                  </ruby>
                  <ruby>
                    消<rt>け</rt>
                  </ruby>
                  す
                </div>
              </Button>
              <Button
                className="inline-flex justify-center"
                type="submit"
                size={Button.size.xl}
                buttonType={
                  'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
                }
              >
                <div>
                  <ruby>
                    結果<rt>けっか</rt>
                  </ruby>
                  を見る
                </div>
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  )

  return (
    <TochigiLayout>
      <div ref={layoutRef}></div>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      {showConfirmationSection ? (
        <FitnessConfirmationSection
          setShowConfirmationSection={setShowConfirmationSection}
        />
      ) : (
        <>
          {fitnessStepsNavigation}
          <div className="mt-10">
            {profileSubmitted ? testForm : basicInfoForm}
          </div>
        </>
      )}
    </TochigiLayout>
  )
}

const FitnessConfirmationSection: React.FC<any> = ({
  setShowConfirmationSection,
}) => {
  const history = useHistory()

  const tochigiContext = useContext(TochigiContext)

  const basicInfoColumns: TableColumnData[] = [
    {
      key: 'key',
      label: 'key',
      value: ['key'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'value',
      label: 'value',
      value: ['value'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
  ]

  const testResultColumns: TableColumnData[] = [
    {
      key: 'key',
      label: 'key',
      value: ['key'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
    {
      key: 'value',
      label: 'value',
      value: ['value'],
      labelHidden: true,
      render: (value) => <Table.Cell>{value}</Table.Cell>,
    },
  ]

  return (
    <div className="bg-white px-4 py-5 rounded-md">
      <h2 className="font-medium text-gray-900 text-2xl tracking-widest mb-4">
        <ruby>
          情報<rt>じょうほう</rt>
        </ruby>
        の
        <ruby>
          確認<rt>かくにん</rt>
        </ruby>
      </h2>

      <div className="px-4 py-4 space-y-1 tracking-wide">
        <h2 className="font-medium text-gray-900 text-lg tracking-widest mb-4">
          <ruby>
            基本情報<rt>きほんじょうほう</rt>
          </ruby>
        </h2>

        <div>
          <Table
            data={[
              {
                key: (
                  <ruby>
                    名前<rt>なまえ</rt>
                  </ruby>
                ),
                value: tochigiContext.basicInfo?.name,
              },
              {
                key: (
                  <ruby>
                    学校情報<rt>がっこうじょうほう</rt>
                  </ruby>
                ),
                value: `${
                  tochigiContext.basicInfo.schoolType
                    ? schoolTypeToString(tochigiContext.basicInfo.schoolType)
                    : ''
                } ${
                  schoolGrades
                    .find((item) => item.value === tochigiContext.basicInfo.age)
                    ?.name.split(' ')[1]
                }`,
              },
              {
                key: (
                  <ruby>
                    性別<rt>せいべつ</rt>
                  </ruby>
                ),
                value:
                  tochigiContext.basicInfo.gender === 'MALE' ? '男子' : '女子',
              },
              {
                key: (
                  <ruby>
                    身長<rt>しんちょう</rt>
                  </ruby>
                ),
                value: `${tochigiContext.basicInfo.height ?? '-'} cm`,
              },
              {
                key: (
                  <ruby>
                    体重<rt>たいじゅう</rt>
                  </ruby>
                ),
                value: `${tochigiContext.basicInfo.weight ?? '-'} kg`,
              },
            ]}
            columns={basicInfoColumns}
          />
        </div>
      </div>

      <div className="px-4 py-4 space-y-1 tracking-wide">
        <h2 className="font-medium text-gray-900 text-lg tracking-widest mb-4">
          <ruby>
            体力結果<rt>たいりょくけっか</rt>
          </ruby>
        </h2>

        <div>
          <Table
            data={[
              {
                key: (
                  <ruby>
                    握力<rt>あくりょく</rt>
                  </ruby>
                ),
                value: `${tochigiContext.testResults?.grip ?? '-'} kg`,
              },
              {
                key: (
                  <span>
                    <ruby>
                      上体起<rt>じょうたいお</rt>
                    </ruby>
                    こし
                  </span>
                ),
                value: `${tochigiContext.testResults?.sitUps ?? '-'} 回`,
              },
              {
                key: (
                  <ruby>
                    長座体前屈<rt>ちょうざたいぜんくつ</rt>
                  </ruby>
                ),
                value: `${tochigiContext.testResults?.bending ?? '-'} cm`,
              },
              {
                key: (
                  <ruby>
                    反復横<rt>はんぷくよこ</rt>とび
                  </ruby>
                ),
                value: `${tochigiContext.testResults?.sideJump ?? '-'} 点`,
              },
              {
                key: <ruby>20mシャトルラン</ruby>,
                value: `${tochigiContext.testResults?.shuttleRun ?? '-'} 回`,
              },
              {
                key: (
                  <span>
                    50m
                    <ruby>
                      走<rt>そう</rt>
                    </ruby>
                  </span>
                ),
                value: `${tochigiContext.testResults?.sprintRun ?? '-'} 秒`,
              },
              {
                key: (
                  <span>
                    <ruby>
                      立<rt>た</rt>
                    </ruby>
                    ち
                    <ruby>
                      幅<rt>はば</rt>
                    </ruby>
                    とび
                  </span>
                ),
                value: `${tochigiContext.testResults?.standingJump ?? '-'} cm`,
              },
              {
                key: (
                  <span>
                    ボール
                    <ruby>
                      投<rt>な</rt>
                    </ruby>
                    げ
                  </span>
                ),
                value: `${tochigiContext.testResults?.handballThrow ?? '-'} m`,
              },
            ]}
            columns={testResultColumns}
          />
        </div>
      </div>

      <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex flex-row justify-end rounded-sm mt-4">
        <div className="flex flex-row space-x-4">
          <Button
            buttonType={Button.buttonType.secondary}
            onClick={async () => {
              await tochigiContext
                .deleteTestResults(tochigiContext.testResults._id)
                .catch((err) => {
                  console.log('🚀', err)
                })
              // window.location.replace('/fitness?step=info')
              setShowConfirmationSection(false)
            }}
          >
            <div>
              <ruby>
                修正<rt>しゅうせい</rt>
              </ruby>
              する
            </div>
          </Button>
          <Button
            onClick={() => {
              history.push(
                `/results/${tochigiContext.testResults._id}?tab=grip`,
              )
            }}
            buttonType={
              'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
            }
          >
            <div>
              <ruby>
                結果<rt>けっか</rt>
              </ruby>
              を
              <ruby>
                見<rt>み</rt>
              </ruby>
              る
            </div>
          </Button>
        </div>
      </div>
    </div>
  )
}

function schoolTypeToString(schoolType: string) {
  return schoolType === 'ELEMENTARY_SCHOOL'
    ? '小学校'
    : schoolType === 'MIDDLE_SCHOOL'
    ? '中学校'
    : '高等学校'
}
