import React from 'react'

import { TochigiLayout } from '../layout/TochigiLayout'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'

export const VideosPage: React.FC<any> = () => {
  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      <div className="flex flex-col items-center pb-10">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-4">
          {/* 握力 */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/TxL-OxjlE8o"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 上体起こし */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/Rs_3xqqqnmo"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 長座体前屈 */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/0XRkWlRcOU4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 反復横とび */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/1LA4LzRyous"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 20mシャトルラン */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/2eV94Zczm0Y"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 50m走 */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/NH-Gt-HfjCA"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* 立ち幅とび */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/zsVJdq9TyD4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
          {/* ソフトボール投げ */}
          <div className="">
            <iframe
              width="360"
              height="202"
              src="https://www.youtube.com/embed/-Gas00GztQg"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    </TochigiLayout>
  )
}
