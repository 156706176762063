export enum UserGender {
  NotSet = 'NOT_SET',
  Male = 'MALE',
  Female = 'FEMALE',
}

export const Grip = {
  computeValue: (
    left1: number,
    left2: number,
    right1: number,
    right2: number,
  ) => {
    let left = Math.max(left1, left2)
    let right = Math.max(right1, right2)

    left = Math.floor(left)
    right = Math.floor(right)

    return Math.round((left + right) / 2)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = Grip.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Grip.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = Grip.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Grip.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = Grip.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = Grip.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = Grip.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = Grip.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = Grip.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = Grip.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [26, 1000] },
      9: { Range: [23, 25] },
      8: { Range: [20, 22] },
      7: { Range: [17, 19] },
      6: { Range: [14, 16] },
      5: { Range: [11, 13] },
      4: { Range: [9, 10] },
      3: { Range: [7, 8] },
      2: { Range: [5, 6] },
      1: { Range: [1, 4] },
    },
    FEMALE: {
      10: { Range: [25, 1000] },
      9: { Range: [22, 24] },
      8: { Range: [19, 21] },
      7: { Range: [16, 18] },
      6: { Range: [13, 15] },
      5: { Range: [11, 12] },
      4: { Range: [9, 10] },
      3: { Range: [7, 8] },
      2: { Range: [4, 6] },
      1: { Range: [1, 3] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [56, 1000] },
      9: { Range: [51, 55] },
      8: { Range: [47, 50] },
      7: { Range: [43, 46] },
      6: { Range: [38, 42] },
      5: { Range: [33, 37] },
      4: { Range: [28, 32] },
      3: { Range: [23, 27] },
      2: { Range: [18, 22] },
      1: { Range: [1, 17] },
    },
    FEMALE: {
      10: { Range: [36, 1000] },
      9: { Range: [33, 35] },
      8: { Range: [30, 32] },
      7: { Range: [28, 29] },
      6: { Range: [25, 27] },
      5: { Range: [23, 24] },
      4: { Range: [20, 22] },
      3: { Range: [17, 19] },
      2: { Range: [14, 16] },
      1: { Range: [1, 13] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [13, 1000] },
        4: { Range: [11, 12] },
        3: { Range: [8, 10] },
        2: { Range: [6, 7] },
        1: { Range: [0, 5] },
      },
      7: {
        5: { Range: [15, 1000] },
        4: { Range: [13, 14] },
        3: { Range: [10, 12] },
        2: { Range: [7, 9] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [17, 1000] },
        4: { Range: [14, 16] },
        3: { Range: [11, 13] },
        2: { Range: [8, 10] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [21, 1000] },
        4: { Range: [17, 20] },
        3: { Range: [13, 16] },
        2: { Range: [9, 12] },
        1: { Range: [0, 8] },
      },
      10: {
        5: { Range: [23, 1000] },
        4: { Range: [19, 22] },
        3: { Range: [15, 18] },
        2: { Range: [11, 14] },
        1: { Range: [0, 10] },
      },
      11: {
        5: { Range: [27, 1000] },
        4: { Range: [22, 26] },
        3: { Range: [17, 21] },
        2: { Range: [13, 16] },
        1: { Range: [0, 12] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [12, 1000] },
        4: { Range: [10, 11] },
        3: { Range: [8, 9] },
        2: { Range: [6, 7] },
        1: { Range: [0, 5] },
      },
      7: {
        5: { Range: [15, 1000] },
        4: { Range: [12, 14] },
        3: { Range: [9, 11] },
        2: { Range: [7, 8] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [17, 1000] },
        4: { Range: [14, 16] },
        3: { Range: [11, 13] },
        2: { Range: [8, 10] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [20, 1000] },
        4: { Range: [16, 19] },
        3: { Range: [12, 15] },
        2: { Range: [9, 11] },
        1: { Range: [0, 8] },
      },
      10: {
        5: { Range: [23, 1000] },
        4: { Range: [19, 22] },
        3: { Range: [15, 18] },
        2: { Range: [11, 14] },
        1: { Range: [0, 10] },
      },
      11: {
        5: { Range: [26, 1000] },
        4: { Range: [22, 25] },
        3: { Range: [17, 21] },
        2: { Range: [13, 16] },
        1: { Range: [0, 12] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [34, 1000] },
        4: { Range: [28, 33] },
        3: { Range: [22, 27] },
        2: { Range: [15, 21] },
        1: { Range: [0, 14] },
      },
      13: {
        5: { Range: [41, 1000] },
        4: { Range: [34, 40] },
        3: { Range: [26, 33] },
        2: { Range: [19, 25] },
        1: { Range: [0, 18] },
      },
      14: {
        5: { Range: [46, 1000] },
        4: { Range: [39, 45] },
        3: { Range: [31, 38] },
        2: { Range: [23, 30] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [30, 1000] },
        4: { Range: [25, 29] },
        3: { Range: [20, 24] },
        2: { Range: [16, 19] },
        1: { Range: [0, 15] },
      },
      13: {
        5: { Range: [32, 1000] },
        4: { Range: [27, 31] },
        3: { Range: [22, 26] },
        2: { Range: [18, 21] },
        1: { Range: [0, 17] },
      },
      14: {
        5: { Range: [33, 1000] },
        4: { Range: [28, 32] },
        3: { Range: [23, 27] },
        2: { Range: [19, 22] },
        1: { Range: [0, 18] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [48, 1000] },
        4: { Range: [41, 47] },
        3: { Range: [34, 40] },
        2: { Range: [27, 33] },
        1: { Range: [0, 26] },
      },
      16: {
        5: { Range: [51, 1000] },
        4: { Range: [44, 50] },
        3: { Range: [36, 43] },
        2: { Range: [29, 35] },
        1: { Range: [0, 28] },
      },
      17: {
        5: { Range: [53, 1000] },
        4: { Range: [46, 52] },
        3: { Range: [38, 45] },
        2: { Range: [30, 37] },
        1: { Range: [0, 29] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [34, 1000] },
        4: { Range: [29, 33] },
        3: { Range: [24, 28] },
        2: { Range: [19, 23] },
        1: { Range: [0, 18] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [30, 34] },
        3: { Range: [24, 29] },
        2: { Range: [19, 23] },
        1: { Range: [0, 18] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [30, 34] },
        3: { Range: [25, 29] },
        2: { Range: [20, 24] },
        1: { Range: [0, 19] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'きろくをのばすコツは、そくていまえにストレッチをおこなうことです。手くびをまわしたり、ブラブラさせたり、りょううでを前にのばしてグーパーうんどうをくりかえしましょう。きんにくがあたたまってきたら、おおきくいきをすってからいっきにつよくはきながらあくりょくけいをにぎります。まえよりもきろくがのびるイメージをもってそくていしましょう。',
    4: 'きろくをのばすコツは、そくていまえにストレッチをおこなうことです。手くびをまわしたり、ブラブラさせたり、りょううでを前にのばしてグーパーうんどうをくりかえしましょう。きんにくがあたたまってきたら、おおきくいきをすってからいっきにつよくはきながらあくりょくけいをにぎります。まえよりもきろくがのびるイメージをもってそくていしましょう。',
    3: 'あくりょくけいをにぎるしゅんかんに、つよい力をだすことが大せつです。大きなこえを出すと、のうからしんごうが出てきんにくが力をだせるようになります。ひごろからグーパーうんどうをおふろのおゆの中でおこなうとあくりょくがアップしますよ。',
    2: 'あくりょくをはかるときのかんたんなコツがあります。それは、おおきなこえをだしながらいっきにぐっとにぎってみることです。おおきなこえを出すのがはずかしい人は、いきをフッとつよくはきながらにぎりましょう。にがてな人は、１日に２０～２９かいグーパーうんどうをするといいですよ。',
    1: 'あくりょくをはかるときのかんたんなコツがあります。それは、おおきなこえをだしながらいっきにぐっとにぎってみることです。おおきなこえを出すのがはずかしい人は、いきをフッとつよくはきながらにぎりましょう。にがてな人は、１日に２０～２９かいグーパーうんどうをするといいですよ。',
  },
  middleSchoolComments: {
    5: '握力を測定する前に、自分の手に合うように握りやすさを確認しましょう。親指の付け根にあわせ、中指、薬指の第二関節が曲がるように握りの幅を調整します。力を入れ続けても変わらないので、一瞬で力を出し切りましょう。リラックスしてからスタートするのがコツです。',
    4: '握力を測定する前に、自分の手に合うように握りやすさを確認しましょう。親指の付け根にあわせ、中指、薬指の第二関節が曲がるように握りの幅を調整します。力を入れ続けても変わらないので、一瞬で力を出し切りましょう。リラックスしてからスタートするのがコツです。',
    3: '計測前にストレッチをして手を温めておきましょう。ほとんど動くことも無く、握力計を握るだけで測定ができますが、瞬間的な力が重要なポイントになります。真っ直ぐな姿勢で両足を少し開き、利き腕を自然に下げましょう。お勧めのトレーニングは懸垂です。',
    2: '握力測定で思うように力を出せないこともあります。日頃から力を入れて強く手を握る、開くの繰り返しを続ける練習も効果的です。測定時には、瞬間の力が計測されますので、思い切り声を出したり、息を吐きながら集中しておこないましょう。',
    1: '握力測定で思うように力を出せないこともあります。日頃から力を入れて強く手を握る、開くの繰り返しを続ける練習も効果的です。測定時には、瞬間の力が計測されますので、思い切り声を出したり、息を吐きながら集中しておこないましょう。',
  },
  highSchoolComments: {
    5: '握力を測定する前に、自分の手に合うように握りやすさを確認しましょう。親指の付け根にあわせ、中指、薬指の第二関節が曲がるように握りの幅を調整します。力を入れ続けても変わらないので、一瞬で力を出し切りましょう。リラックスしてからスタートするのがコツです。',
    4: '握力を測定する前に、自分の手に合うように握りやすさを確認しましょう。親指の付け根にあわせ、中指、薬指の第二関節が曲がるように握りの幅を調整します。力を入れ続けても変わらないので、一瞬で力を出し切りましょう。リラックスしてからスタートするのがコツです。',
    3: '計測前にストレッチをして手を温めておきましょう。ほとんど動くことも無く、握力計を握るだけで測定ができますが、瞬間的な力が重要なポイントになります。真っ直ぐな姿勢で両足を少し開き、利き腕を自然に下げましょう。お勧めのトレーニングは懸垂です。',
    2: '握力測定で思うように力を出せないこともあります。日頃から力を入れて強く手を握る、開くの繰り返しを続ける練習も効果的です。測定時には、瞬間の力が計測されますので、思い切り声を出したり、息を吐きながら集中しておこないましょう。',
    1: '握力測定で思うように力を出せないこともあります。日頃から力を入れて強く手を握る、開くの繰り返しを続ける練習も効果的です。測定時には、瞬間の力が計測されますので、思い切り声を出したり、息を吐きながら集中しておこないましょう。',
  },
}

export const SitUps = {
  computeValue: (sitUps: number) => {
    return Math.round(sitUps)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SitUps.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SitUps.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SitUps.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SitUps.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SitUps.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SitUps.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SitUps.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SitUps.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SitUps.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SitUps.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [26, 1000] },
      9: { Range: [23, 25] },
      8: { Range: [20, 22] },
      7: { Range: [18, 19] },
      6: { Range: [15, 17] },
      5: { Range: [12, 14] },
      4: { Range: [9, 11] },
      3: { Range: [6, 8] },
      2: { Range: [3, 5] },
      1: { Range: [1, 2] },
    },
    FEMALE: {
      10: { Range: [23, 1000] },
      9: { Range: [20, 22] },
      8: { Range: [18, 19] },
      7: { Range: [16, 17] },
      6: { Range: [14, 15] },
      5: { Range: [12, 13] },
      4: { Range: [9, 11] },
      3: { Range: [6, 8] },
      2: { Range: [3, 5] },
      1: { Range: [1, 2] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [35, 1000] },
      9: { Range: [33, 34] },
      8: { Range: [30, 32] },
      7: { Range: [27, 29] },
      6: { Range: [25, 26] },
      5: { Range: [22, 24] },
      4: { Range: [19, 21] },
      3: { Range: [16, 18] },
      2: { Range: [13, 15] },
      1: { Range: [1, 12] },
    },
    FEMALE: {
      10: { Range: [29, 1000] },
      9: { Range: [26, 28] },
      8: { Range: [23, 25] },
      7: { Range: [20, 22] },
      6: { Range: [18, 19] },
      5: { Range: [15, 17] },
      4: { Range: [13, 14] },
      3: { Range: [11, 12] },
      2: { Range: [8, 10] },
      1: { Range: [1, 7] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [10, 14] },
        2: { Range: [4, 9] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [23, 1000] },
        4: { Range: [18, 22] },
        3: { Range: [12, 17] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [26, 1000] },
        4: { Range: [20, 25] },
        3: { Range: [14, 19] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [28, 1000] },
        4: { Range: [22, 27] },
        3: { Range: [16, 21] },
        2: { Range: [10, 15] },
        1: { Range: [0, 9] },
      },
      10: {
        5: { Range: [30, 1000] },
        4: { Range: [24, 29] },
        3: { Range: [18, 23] },
        2: { Range: [12, 17] },
        1: { Range: [0, 11] },
      },
      11: {
        5: { Range: [31, 1000] },
        4: { Range: [26, 30] },
        3: { Range: [20, 25] },
        2: { Range: [14, 19] },
        1: { Range: [0, 13] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [20, 1000] },
        4: { Range: [14, 19] },
        3: { Range: [9, 13] },
        2: { Range: [4, 8] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [23, 1000] },
        4: { Range: [17, 22] },
        3: { Range: [12, 16] },
        2: { Range: [6, 11] },
        1: { Range: [0, 5] },
      },
      8: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [14, 18] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
      9: {
        5: { Range: [27, 1000] },
        4: { Range: [21, 26] },
        3: { Range: [15, 20] },
        2: { Range: [10, 14] },
        1: { Range: [0, 9] },
      },
      10: {
        5: { Range: [28, 1000] },
        4: { Range: [23, 27] },
        3: { Range: [17, 22] },
        2: { Range: [12, 16] },
        1: { Range: [0, 11] },
      },
      11: {
        5: { Range: [29, 1000] },
        4: { Range: [24, 28] },
        3: { Range: [19, 23] },
        2: { Range: [13, 18] },
        1: { Range: [0, 12] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [34, 1000] },
        4: { Range: [28, 33] },
        3: { Range: [22, 27] },
        2: { Range: [15, 21] },
        1: { Range: [0, 14] },
      },
      13: {
        5: { Range: [38, 1000] },
        4: { Range: [31, 37] },
        3: { Range: [25, 30] },
        2: { Range: [19, 24] },
        1: { Range: [0, 18] },
      },
      14: {
        5: { Range: [40, 1000] },
        4: { Range: [34, 39] },
        3: { Range: [27, 33] },
        2: { Range: [21, 26] },
        1: { Range: [0, 20] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [30, 1000] },
        4: { Range: [25, 29] },
        3: { Range: [19, 24] },
        2: { Range: [13, 18] },
        1: { Range: [0, 12] },
      },
      13: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      14: {
        5: { Range: [35, 1000] },
        4: { Range: [28, 34] },
        3: { Range: [22, 27] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [39, 1000] },
        4: { Range: [33, 38] },
        3: { Range: [27, 32] },
        2: { Range: [21, 26] },
        1: { Range: [0, 20] },
      },
      16: {
        5: { Range: [42, 1000] },
        4: { Range: [35, 41] },
        3: { Range: [29, 34] },
        2: { Range: [23, 28] },
        1: { Range: [0, 22] },
      },
      17: {
        5: { Range: [42, 1000] },
        4: { Range: [35, 41] },
        3: { Range: [29, 34] },
        2: { Range: [23, 28] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'こきゅうのリズムをつかむこともたいせつです。からだをおこすときにいきをはき、せなかがつくときには、いきをすいましょう。うごかすスピードはゆっくりではなく、はやくおこなうほうが、力をつかうじかんがみじかくなり、つかれがたまりません。こしにちかいももにタッチし、うごかすきょりをみじかくしましょう。',
    4: 'こきゅうのリズムをつかむこともたいせつです。からだをおこすときにいきをはき、せなかがつくときには、いきをすいましょう。うごかすスピードはゆっくりではなく、はやくおこなうほうが、力をつかうじかんがみじかくなり、つかれがたまりません。こしにちかいももにタッチし、うごかすきょりをみじかくしましょう。',
    3: 'りょううでをむねのまえでくむときは、ひじをさげるのがコツです。ももにつくまでのきょりがみじかくなり、スピードがはやくなります。あごをひいておへそをみるようにしましょう。せなかがついたタイミングではんどうをつけるとらくにおき上がることができるようになります。',
    2: 'あしをもつひともたいせつです。足くびではなくひざの下をしっかりおさえてもらいましょう。おきあがるときは、まずあたまをおこし、おへそをみながらせなかを丸くしておきあがります。おなかだけではなく、足にも力を入れてふんばるようにしましょう。',
    1: 'あしをもつひともたいせつです。足くびではなくひざの下をしっかりおさえてもらいましょう。おきあがるときは、まずあたまをおこし、おへそをみながらせなかを丸くしておきあがります。おなかだけではなく、足にも力を入れてふんばるようにしましょう。',
  },
  middleSchoolComments: {
    5: '足を支える人は、手で足首を持つだけではなく、足に乗ってあぐらをかき、膝の下を抱え込むようにします。こうすることで足のうごきが固定され起き上がりやすくなります。首を曲げて背中を丸めるように起き上がるときに、つま先を上に向けると、さらに起き上がりやすくなります。首にも負荷がかかりますから、準備運動をし柔軟性を高めておきましょう。',
    4: '足を支える人は、手で足首を持つだけではなく、足に乗ってあぐらをかき、膝の下を抱え込むようにします。こうすることで足のうごきが固定され起き上がりやすくなります。首を曲げて背中を丸めるように起き上がるときに、つま先を上に向けると、さらに起き上がりやすくなります。首にも負荷がかかりますから、準備運動をし柔軟性を高めておきましょう。',
    3: '上半身だけで起き上がるより、同時に下半身も使うとスムーズに起き上がれるようになります。地面についたときの反動を使って起き上がりましょう。ゆっくり動作を続けるよりできるだけ速く行うことがポイントです。速く動かすことで力を使う時間を短くすることができます。リズミカルに回数を増やしていきましょう。',
    2: '上体起こしは腹筋と足の筋肉が必要になります。苦手な人もコツをつかんでいきましょう。まず、あごを引いて首を曲げ、背中を丸めるように起き上がります。胸元においた手は動かさずに、太ももにタッチしましょう。息を吐きながら起き上がるといいですね。',
    1: '上体起こしは腹筋と足の筋肉が必要になります。苦手な人もコツをつかんでいきましょう。まず、あごを引いて首を曲げ、背中を丸めるように起き上がります。胸元においた手は動かさずに、太ももにタッチしましょう。息を吐きながら起き上がるといいですね。',
  },
  highSchoolComments: {
    5: '足を支える人は、手で足首を持つだけではなく、足に乗ってあぐらをかき、膝の下を抱え込むようにします。こうすることで足のうごきが固定され起き上がりやすくなります。首を曲げて背中を丸めるように起き上がるときに、つま先を上に向けると、さらに起き上がりやすくなります。首にも負荷がかかりますから、準備運動をし柔軟性を高めておきましょう。',
    4: '足を支える人は、手で足首を持つだけではなく、足に乗ってあぐらをかき、膝の下を抱え込むようにします。こうすることで足のうごきが固定され起き上がりやすくなります。首を曲げて背中を丸めるように起き上がるときに、つま先を上に向けると、さらに起き上がりやすくなります。首にも負荷がかかりますから、準備運動をし柔軟性を高めておきましょう。',
    3: '上半身だけで起き上がるより、同時に下半身も使うとスムーズに起き上がれるようになります。地面についたときの反動を使って起き上がりましょう。ゆっくり動作を続けるよりできるだけ速く行うことがポイントです。速く動かすことで力を使う時間を短くすることができます。リズミカルに回数を増やしていきましょう。',
    2: '上体起こしは腹筋と足の筋肉が必要になります。苦手な人もコツをつかんでいきましょう。まず、あごを引いて首を曲げ、背中を丸めるように起き上がります。胸元においた手は動かさずに、太ももにタッチしましょう。息を吐きながら起き上がるといいですね。',
    1: '上体起こしは腹筋と足の筋肉が必要になります。苦手な人もコツをつかんでいきましょう。まず、あごを引いて首を曲げ、背中を丸めるように起き上がります。胸元においた手は動かさずに、太ももにタッチしましょう。息を吐きながら起き上がるといいですね。',
  },
}

export const Bending = {
  computeValue: (bending1: number, bending2: number) => {
    let bending = Math.max(bending1, bending2)
    return Math.floor(bending)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = Bending.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Bending.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = Bending.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = Bending.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = Bending.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = Bending.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = Bending.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = Bending.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = Bending.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = Bending.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [49, 1000] },
      9: { Range: [43, 48] },
      8: { Range: [38, 42] },
      7: { Range: [34, 37] },
      6: { Range: [30, 33] },
      5: { Range: [27, 29] },
      4: { Range: [23, 26] },
      3: { Range: [19, 22] },
      2: { Range: [15, 18] },
      1: { Range: [1, 14] },
    },
    FEMALE: {
      10: { Range: [52, 1000] },
      9: { Range: [46, 51] },
      8: { Range: [41, 45] },
      7: { Range: [37, 40] },
      6: { Range: [33, 36] },
      5: { Range: [29, 32] },
      4: { Range: [25, 28] },
      3: { Range: [21, 24] },
      2: { Range: [18, 20] },
      1: { Range: [1, 17] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [64, 1000] },
      9: { Range: [58, 63] },
      8: { Range: [53, 57] },
      7: { Range: [49, 52] },
      6: { Range: [44, 48] },
      5: { Range: [39, 43] },
      4: { Range: [33, 38] },
      3: { Range: [28, 32] },
      2: { Range: [21, 27] },
      1: { Range: [1, 20] },
    },
    FEMALE: {
      10: { Range: [63, 1000] },
      9: { Range: [58, 62] },
      8: { Range: [54, 57] },
      7: { Range: [50, 53] },
      6: { Range: [45, 49] },
      5: { Range: [40, 44] },
      4: { Range: [35, 39] },
      3: { Range: [30, 34] },
      2: { Range: [23, 29] },
      1: { Range: [1, 22] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [39, 1000] },
        4: { Range: [31, 38] },
        3: { Range: [24, 30] },
        2: { Range: [16, 23] },
        1: { Range: [0, 15] },
      },
      7: {
        5: { Range: [41, 1000] },
        4: { Range: [33, 40] },
        3: { Range: [24, 32] },
        2: { Range: [17, 23] },
        1: { Range: [0, 16] },
      },
      8: {
        5: { Range: [43, 1000] },
        4: { Range: [35, 42] },
        3: { Range: [26, 34] },
        2: { Range: [18, 25] },
        1: { Range: [0, 17] },
      },
      9: {
        5: { Range: [45, 1000] },
        4: { Range: [36, 44] },
        3: { Range: [28, 35] },
        2: { Range: [20, 27] },
        1: { Range: [0, 19] },
      },
      10: {
        5: { Range: [47, 1000] },
        4: { Range: [39, 46] },
        3: { Range: [30, 38] },
        2: { Range: [21, 29] },
        1: { Range: [0, 20] },
      },
      11: {
        5: { Range: [49, 1000] },
        4: { Range: [40, 48] },
        3: { Range: [32, 39] },
        2: { Range: [23, 31] },
        1: { Range: [0, 22] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [41, 1000] },
        4: { Range: [33, 40] },
        3: { Range: [25, 32] },
        2: { Range: [18, 24] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [43, 1000] },
        4: { Range: [36, 42] },
        3: { Range: [28, 35] },
        2: { Range: [20, 27] },
        1: { Range: [0, 19] },
      },
      8: {
        5: { Range: [46, 1000] },
        4: { Range: [37, 45] },
        3: { Range: [29, 36] },
        2: { Range: [21, 28] },
        1: { Range: [0, 20] },
      },
      9: {
        5: { Range: [48, 1000] },
        4: { Range: [40, 47] },
        3: { Range: [31, 39] },
        2: { Range: [23, 30] },
        1: { Range: [0, 22] },
      },
      10: {
        5: { Range: [52, 1000] },
        4: { Range: [43, 51] },
        3: { Range: [34, 42] },
        2: { Range: [25, 33] },
        1: { Range: [0, 24] },
      },
      11: {
        5: { Range: [54, 1000] },
        4: { Range: [45, 53] },
        3: { Range: [36, 44] },
        2: { Range: [27, 35] },
        1: { Range: [0, 26] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [56, 1000] },
        4: { Range: [46, 55] },
        3: { Range: [36, 45] },
        2: { Range: [26, 35] },
        1: { Range: [0, 25] },
      },
      13: {
        5: { Range: [62, 1000] },
        4: { Range: [51, 61] },
        3: { Range: [40, 50] },
        2: { Range: [29, 39] },
        1: { Range: [0, 28] },
      },
      14: {
        5: { Range: [66, 1000] },
        4: { Range: [54, 65] },
        3: { Range: [43, 53] },
        2: { Range: [32, 42] },
        1: { Range: [0, 31] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [60, 1000] },
        4: { Range: [50, 59] },
        3: { Range: [39, 49] },
        2: { Range: [29, 38] },
        1: { Range: [0, 28] },
      },
      13: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [42, 52] },
        2: { Range: [32, 41] },
        1: { Range: [0, 31] },
      },
      14: {
        5: { Range: [65, 1000] },
        4: { Range: [55, 64] },
        3: { Range: [44, 54] },
        2: { Range: [34, 43] },
        1: { Range: [0, 33] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [63, 1000] },
        4: { Range: [52, 62] },
        3: { Range: [42, 51] },
        2: { Range: [31, 41] },
        1: { Range: [0, 30] },
      },
      16: {
        5: { Range: [66, 1000] },
        4: { Range: [55, 65] },
        3: { Range: [44, 54] },
        2: { Range: [33, 43] },
        1: { Range: [0, 32] },
      },
      17: {
        5: { Range: [68, 1000] },
        4: { Range: [57, 67] },
        3: { Range: [45, 56] },
        2: { Range: [34, 44] },
        1: { Range: [0, 33] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [43, 52] },
        2: { Range: [33, 42] },
        1: { Range: [0, 32] },
      },
      16: {
        5: { Range: [63, 1000] },
        4: { Range: [53, 62] },
        3: { Range: [43, 52] },
        2: { Range: [34, 42] },
        1: { Range: [0, 33] },
      },
      17: {
        5: { Range: [65, 1000] },
        4: { Range: [54, 64] },
        3: { Range: [44, 53] },
        2: { Range: [34, 43] },
        1: { Range: [0, 33] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'そくていまえに、ふともものうらがわやふくらはぎをストレッチしてのばしておきましょう。まず、ちからをぬいて、じかんをかけてゆっくり、リラックスしながらぜんくつのれんしゅうをします。そくていするときは、いきをはきながらおこなうと、きんちょうがなくなりきんにくもゆるみます。さいごは、いきをはききるようにすると記録がのびますよ。',
    4: 'そくていまえに、ふともものうらがわやふくらはぎをストレッチしてのばしておきましょう。まず、ちからをぬいて、じかんをかけてゆっくり、リラックスしながらぜんくつのれんしゅうをします。そくていするときは、いきをはきながらおこなうと、きんちょうがなくなりきんにくもゆるみます。さいごは、いきをはききるようにすると記録がのびますよ。',
    3: 'そくていは、いきをはきながらおこなうと、きんちょうがなくなり、リラックスしていいきろくがでるようになります。はじめるまえに、ふともものうらがわをすうかいなでたり、のばしたりしていきましょう。ひごろから、からだがあたたまっているおふろあがりに、ストレッチをするとからだがやわらかくなっていきますよ。',
    2: 'にがてだとかんじる人は、そくていするまえに、ふともものうらがわをしっかりのばしていきます。力をぬいてリラックスしたじょうたいではじめましょう。手だけをのばそうとしていませんか。　おへそをまえにだすようにして、ふとももにくっつけるように、からだをたおしていきましょう。',
    1: 'にがてだとかんじる人は、そくていするまえに、ふともものうらがわをしっかりのばしていきます。力をぬいてリラックスしたじょうたいではじめましょう。手だけをのばそうとしていませんか。　おへそをまえにだすようにして、ふとももにくっつけるように、からだをたおしていきましょう。',
  },
  middleSchoolComments: {
    5: '柔軟性が高いと全身を使えるようになり、運動時の怪我防止にもなります。息を吐きながら測定しますが、最後の力を振り絞るときには、息を全て吐ききるイメージでやるのがコツです。体の曲げ方は股関節から曲げるようにし、お腹が太ももについているかどうかで確認をしましょう。',
    4: '柔軟性が高いと全身を使えるようになり、運動時の怪我防止にもなります。息を吐きながら測定しますが、最後の力を振り絞るときには、息を全て吐ききるイメージでやるのがコツです。体の曲げ方は股関節から曲げるようにし、お腹が太ももについているかどうかで確認をしましょう。',
    3: '測定をする前に、足の裏側、お尻、腰、背中の筋肉をしっかりと伸ばしておきましょう。リラックスしながら力を抜いてストレッチを行います。計測するときは緊張を解くために息を吐きましょう。息を吐いているときは緊張がとけて筋肉も緩みます。',
    2: '長座前屈は柔軟性をみる体力テストです。苦手と感じている場合のコツがあります。おへそを見るようにし腕を前に伸ばしながら、両腕の中に頭を入れるイメージを持ちましょう。測定を始める前に肩の筋肉や関節を緩めるようなストレッチをしておくことも大切です。',
    1: '長座前屈は柔軟性をみる体力テストです。苦手と感じている場合のコツがあります。おへそを見るようにし腕を前に伸ばしながら、両腕の中に頭を入れるイメージを持ちましょう。測定を始める前に肩の筋肉や関節を緩めるようなストレッチをしておくことも大切です。',
  },
  highSchoolComments: {
    5: '柔軟性が高いと全身を使えるようになり、運動時の怪我防止にもなります。息を吐きながら測定しますが、最後の力を振り絞るときには、息を全て吐ききるイメージでやるのがコツです。体の曲げ方は股関節から曲げるようにし、お腹が太ももについているかどうかで確認をしましょう。',
    4: '柔軟性が高いと全身を使えるようになり、運動時の怪我防止にもなります。息を吐きながら測定しますが、最後の力を振り絞るときには、息を全て吐ききるイメージでやるのがコツです。体の曲げ方は股関節から曲げるようにし、お腹が太ももについているかどうかで確認をしましょう。',
    3: '測定をする前に、足の裏側、お尻、腰、背中の筋肉をしっかりと伸ばしておきましょう。リラックスしながら力を抜いてストレッチを行います。計測するときは緊張を解くために息を吐きましょう。息を吐いているときは緊張がとけて筋肉も緩みます。',
    2: '長座前屈は柔軟性をみる体力テストです。苦手と感じている場合のコツがあります。おへそを見るようにし腕を前に伸ばしながら、両腕の中に頭を入れるイメージを持ちましょう。測定を始める前に肩の筋肉や関節を緩めるようなストレッチをしておくことも大切です。',
    1: '長座前屈は柔軟性をみる体力テストです。苦手と感じている場合のコツがあります。おへそを見るようにし腕を前に伸ばしながら、両腕の中に頭を入れるイメージを持ちましょう。測定を始める前に肩の筋肉や関節を緩めるようなストレッチをしておくことも大切です。',
  },
}

export const SideJump = {
  computeValue: (sideJump1: number, sideJump2: number) => {
    let sideJump = Math.max(sideJump1, sideJump2)
    return Math.floor(sideJump)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SideJump.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SideJump.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SideJump.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SideJump.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SideJump.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SideJump.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SideJump.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SideJump.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SideJump.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SideJump.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [50, 1000] },
      9: { Range: [46, 49] },
      8: { Range: [42, 45] },
      7: { Range: [38, 41] },
      6: { Range: [34, 37] },
      5: { Range: [30, 33] },
      4: { Range: [26, 29] },
      3: { Range: [22, 25] },
      2: { Range: [18, 21] },
      1: { Range: [1, 17] },
    },
    FEMALE: {
      10: { Range: [47, 1000] },
      9: { Range: [43, 46] },
      8: { Range: [40, 42] },
      7: { Range: [36, 39] },
      6: { Range: [32, 35] },
      5: { Range: [28, 31] },
      4: { Range: [25, 27] },
      3: { Range: [21, 24] },
      2: { Range: [17, 20] },
      1: { Range: [1, 16] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [63, 1000] },
      9: { Range: [60, 62] },
      8: { Range: [56, 59] },
      7: { Range: [53, 55] },
      6: { Range: [49, 52] },
      5: { Range: [45, 48] },
      4: { Range: [41, 44] },
      3: { Range: [37, 40] },
      2: { Range: [30, 36] },
      1: { Range: [1, 29] },
    },
    FEMALE: {
      10: { Range: [53, 1000] },
      9: { Range: [50, 52] },
      8: { Range: [48, 49] },
      7: { Range: [45, 47] },
      6: { Range: [42, 44] },
      5: { Range: [39, 41] },
      4: { Range: [36, 38] },
      3: { Range: [32, 35] },
      2: { Range: [27, 31] },
      1: { Range: [1, 26] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [36, 1000] },
        4: { Range: [30, 35] },
        3: { Range: [24, 29] },
        2: { Range: [18, 23] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [42, 1000] },
        4: { Range: [34, 41] },
        3: { Range: [27, 33] },
        2: { Range: [20, 26] },
        1: { Range: [0, 19] },
      },
      8: {
        5: { Range: [47, 1000] },
        4: { Range: [39, 46] },
        3: { Range: [30, 38] },
        2: { Range: [22, 29] },
        1: { Range: [0, 21] },
      },
      9: {
        5: { Range: [51, 1000] },
        4: { Range: [43, 50] },
        3: { Range: [34, 42] },
        2: { Range: [26, 33] },
        1: { Range: [0, 25] },
      },
      10: {
        5: { Range: [55, 1000] },
        4: { Range: [47, 54] },
        3: { Range: [38, 46] },
        2: { Range: [30, 37] },
        1: { Range: [0, 29] },
      },
      11: {
        5: { Range: [57, 1000] },
        4: { Range: [49, 56] },
        3: { Range: [42, 48] },
        2: { Range: [34, 41] },
        1: { Range: [0, 33] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [34, 1000] },
        4: { Range: [29, 33] },
        3: { Range: [23, 28] },
        2: { Range: [18, 22] },
        1: { Range: [0, 17] },
      },
      7: {
        5: { Range: [40, 1000] },
        4: { Range: [33, 39] },
        3: { Range: [26, 32] },
        2: { Range: [19, 25] },
        1: { Range: [0, 18] },
      },
      8: {
        5: { Range: [44, 1000] },
        4: { Range: [37, 43] },
        3: { Range: [29, 36] },
        2: { Range: [21, 28] },
        1: { Range: [0, 20] },
      },
      9: {
        5: { Range: [48, 1000] },
        4: { Range: [41, 47] },
        3: { Range: [33, 40] },
        2: { Range: [26, 32] },
        1: { Range: [0, 25] },
      },
      10: {
        5: { Range: [52, 1000] },
        4: { Range: [45, 51] },
        3: { Range: [37, 44] },
        2: { Range: [30, 36] },
        1: { Range: [0, 29] },
      },
      11: {
        5: { Range: [54, 1000] },
        4: { Range: [47, 53] },
        3: { Range: [40, 46] },
        2: { Range: [33, 39] },
        1: { Range: [0, 32] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [62, 1000] },
        4: { Range: [54, 61] },
        3: { Range: [46, 53] },
        2: { Range: [38, 45] },
        1: { Range: [0, 37] },
      },
      13: {
        5: { Range: [66, 1000] },
        4: { Range: [58, 65] },
        3: { Range: [49, 57] },
        2: { Range: [41, 48] },
        1: { Range: [0, 40] },
      },
      14: {
        5: { Range: [69, 1000] },
        4: { Range: [60, 68] },
        3: { Range: [52, 59] },
        2: { Range: [44, 51] },
        1: { Range: [0, 43] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [57, 1000] },
        4: { Range: [50, 56] },
        3: { Range: [43, 49] },
        2: { Range: [36, 42] },
        1: { Range: [0, 35] },
      },
      13: {
        5: { Range: [59, 1000] },
        4: { Range: [52, 58] },
        3: { Range: [45, 51] },
        2: { Range: [38, 44] },
        1: { Range: [0, 37] },
      },
      14: {
        5: { Range: [60, 1000] },
        4: { Range: [53, 59] },
        3: { Range: [45, 52] },
        2: { Range: [38, 44] },
        1: { Range: [0, 37] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [67, 1000] },
        4: { Range: [60, 66] },
        3: { Range: [52, 59] },
        2: { Range: [45, 51] },
        1: { Range: [0, 44] },
      },
      16: {
        5: { Range: [69, 1000] },
        4: { Range: [61, 68] },
        3: { Range: [54, 60] },
        2: { Range: [47, 53] },
        1: { Range: [0, 46] },
      },
      17: {
        5: { Range: [70, 1000] },
        4: { Range: [62, 69] },
        3: { Range: [54, 61] },
        2: { Range: [46, 53] },
        1: { Range: [0, 45] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
      16: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
      17: {
        5: { Range: [58, 1000] },
        4: { Range: [52, 57] },
        3: { Range: [45, 51] },
        2: { Range: [39, 44] },
        1: { Range: [0, 38] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'はんぷくよことびはおなじうごきをくりかえします。じゅうしんをつま先におき、ひざをまげて、ひくくかまえましょう。あたまをまん中にのこして、すばやく足をうごかすのがコツです。　ひだりとみぎのラインをこえようとすると、足をうごかすはばが広がってつかれやすくなるので、ラインはふむだけでもいいですね。リズムかんをつかんで、きろくをあげていきましょう。',
    4: 'はんぷくよことびはおなじうごきをくりかえします。じゅうしんをつま先におき、ひざをまげて、ひくくかまえましょう。あたまをまん中にのこして、すばやく足をうごかすのがコツです。　ひだりとみぎのラインをこえようとすると、足をうごかすはばが広がってつかれやすくなるので、ラインはふむだけでもいいですね。リズムかんをつかんで、きろくをあげていきましょう。',
    3: 'あたまをできるだけうごかさずにひざをまげて、足だけをうごかしてリズムをつかんでいきましょう。いそぎすぎるとブレーキをかけすぎてしまうので、足のうごきがにぶくなります。力をぬいておこなうことがたいせつです。ラインはこえずにふむだけでもよいのですが、ふみわすれないようにしましょう。',
    2: 'せんをまたぐときに大きく上にジャンプをしていませんか。じゅうしんを下におとしてひくいしせいのまま、よこにとびましょう。からだをうごかしすぎるより、あたまをできるだけうごかさずに、足だけをうごかしていくのがコツです。力を入れすぎず、リラックスしておこないましょう。',
    1: 'せんをまたぐときに大きく上にジャンプをしていませんか。じゅうしんを下におとしてひくいしせいのまま、よこにとびましょう。からだをうごかしすぎるより、あたまをできるだけうごかさずに、足だけをうごかしていくのがコツです。力を入れすぎず、リラックスしておこないましょう。',
  },
  middleSchoolComments: {
    5: '方向を変換するときは、リズミカルに行いましょう。反復横跳びに必要な筋肉は、太ももとふくらはぎです。スクワットやカーフレイズ（かかと上げ運動）をして下半身の筋肉を鍛え、リラックスしながら点数を上げていきましょう。日頃の練習がいい結果をにつながっていきます。',
    4: '方向を変換するときは、リズミカルに行いましょう。反復横跳びに必要な筋肉は、太ももとふくらはぎです。スクワットやカーフレイズ（かかと上げ運動）をして下半身の筋肉を鍛え、リラックスしながら点数を上げていきましょう。日頃の練習がいい結果をにつながっていきます。',
    3: '腰の位置が高くなると素早く動くことが難しくなります。できるだけ腰の位置を低くし膝を曲げ、重心はつま先です。サイドステップの練習はどこでも線を引くことでできます。中央の線から左右に１メートルのところに平行に線を引きましょう。始めはゆっくり、慣れてきたらスピードアップしましょう。',
    2: '反復横跳びは、同じ動作の繰り返しです。左右に方向を変換させるためのコツは、頭の位置をできるだけ動かさないようにしながら素早く足を動かすことです。膝は曲げ、リズムに乗ってサイドステップを繰り返しましょう。',
    1: '反復横跳びは、同じ動作の繰り返しです。左右に方向を変換させるためのコツは、頭の位置をできるだけ動かさないようにしながら素早く足を動かすことです。膝は曲げ、リズムに乗ってサイドステップを繰り返しましょう。',
  },
  highSchoolComments: {
    5: '方向を変換するときは、リズミカルに行いましょう。反復横跳びに必要な筋肉は、太ももとふくらはぎです。スクワットやカーフレイズ（かかと上げ運動）をして下半身の筋肉を鍛え、リラックスしながら点数を上げていきましょう。日頃の練習がいい結果をにつながっていきます。',
    4: '方向を変換するときは、リズミカルに行いましょう。反復横跳びに必要な筋肉は、太ももとふくらはぎです。スクワットやカーフレイズ（かかと上げ運動）をして下半身の筋肉を鍛え、リラックスしながら点数を上げていきましょう。日頃の練習がいい結果をにつながっていきます。',
    3: '腰の位置が高くなると素早く動くことが難しくなります。できるだけ腰の位置を低くし膝を曲げ、重心はつま先です。サイドステップの練習はどこでも線を引くことでできます。中央の線から左右に１メートルのところに平行に線を引きましょう。始めはゆっくり、慣れてきたらスピードアップしましょう。',
    2: '反復横跳びは、同じ動作の繰り返しです。左右に方向を変換させるためのコツは、頭の位置をできるだけ動かさないようにしながら素早く足を動かすことです。膝は曲げ、リズムに乗ってサイドステップを繰り返しましょう。',
    1: '反復横跳びは、同じ動作の繰り返しです。左右に方向を変換させるためのコツは、頭の位置をできるだけ動かさないようにしながら素早く足を動かすことです。膝は曲げ、リズムに乗ってサイドステップを繰り返しましょう。',
  },
}

export const EnduranceRun = {
  computeValue: (time: number) => {
    let seconds = Math.ceil(time)
    return Math.floor(seconds)
  },
  computePoints: (value: number, age, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        return 0
      }
      let ranges = EnduranceRun.middleSchoolRanges.MALE

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          points = Number(key)
        }
      }
    } else {
      let ranges = EnduranceRun.middleSchoolRanges.FEMALE

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          points = Number(key)
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(12, Math.min(17, age))

    if (age < 15) {
      let ranges = EnduranceRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = EnduranceRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 15) {
      let ranges = EnduranceRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = EnduranceRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  middleSchoolRanges: {
    MALE: {
      1: { Range: [561, 10_000] },
      2: { Range: [500, 560] },
      3: { Range: [451, 499] },
      4: { Range: [411, 450] },
      5: { Range: [383, 410] },
      6: { Range: [356, 382] },
      7: { Range: [334, 355] },
      8: { Range: [317, 333] },
      9: { Range: [300, 316] },
      10: { Range: [1, 299] },
    },
    FEMALE: {
      1: { Range: [418, 10_000] },
      2: { Range: [375, 417] },
      3: { Range: [343, 374] },
      4: { Range: [319, 342] },
      5: { Range: [297, 318] },
      6: { Range: [278, 296] },
      7: { Range: [260, 277] },
      8: { Range: [243, 259] },
      9: { Range: [230, 242] },
      10: { Range: [1, 229] },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        1: { Range: [553, 10_000] },
        2: { Range: [474, 552] },
        3: { Range: [396, 473] },
        4: { Range: [317, 395] },
        5: { Range: [1, 316] },
      },
      13: {
        1: { Range: [517, 10_000] },
        2: { Range: [439, 516] },
        3: { Range: [362, 438] },
        4: { Range: [284, 361] },
        5: { Range: [1, 283] },
      },
      14: {
        1: { Range: [462, 10_000] },
        2: { Range: [406, 461] },
        3: { Range: [350, 405] },
        4: { Range: [294, 349] },
        5: { Range: [1, 293] },
      },
    },
    FEMALE: {
      12: {
        1: { Range: [379, 10_000] },
        2: { Range: [329, 378] },
        3: { Range: [279, 328] },
        4: { Range: [229, 278] },
        5: { Range: [1, 228] },
      },
      13: {
        1: { Range: [374, 10_000] },
        2: { Range: [319, 373] },
        3: { Range: [264, 318] },
        4: { Range: [209, 263] },
        5: { Range: [1, 208] },
      },
      14: {
        1: { Range: [362, 10_000] },
        2: { Range: [316, 361] },
        3: { Range: [269, 315] },
        4: { Range: [223, 268] },
        5: { Range: [1, 222] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        1: { Range: [490, 10_000] },
        2: { Range: [428, 489] },
        3: { Range: [367, 427] },
        4: { Range: [305, 366] },
        5: { Range: [1, 304] },
      },
      16: {
        1: { Range: [483, 10_000] },
        2: { Range: [419, 482] },
        3: { Range: [354, 418] },
        4: { Range: [290, 353] },
        5: { Range: [1, 289] },
      },
      17: {
        1: { Range: [500, 10_000] },
        2: { Range: [428, 499] },
        3: { Range: [356, 427] },
        4: { Range: [284, 355] },
        5: { Range: [1, 283] },
      },
    },
    FEMALE: {
      15: {
        1: { Range: [389, 10_000] },
        2: { Range: [342, 388] },
        3: { Range: [494, 341] },
        4: { Range: [247, 493] },
        5: { Range: [1, 246] },
      },
      16: {
        1: { Range: [387, 10_000] },
        2: { Range: [337, 386] },
        3: { Range: [288, 336] },
        4: { Range: [238, 287] },
        5: { Range: [1, 237] },
      },
      17: {
        1: { Range: [402, 10_000] },
        2: { Range: [346, 401] },
        3: { Range: [289, 345] },
        4: { Range: [232, 288] },
        5: { Range: [1, 231] },
      },
    },
  },
  middleSchoolComments: {
    1: '持久力を高めるために、できることを考えてみましょう。まずはきつくなったら歩く、回復したら走るのを繰り返して３０分程度の継続を目標とし、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになりましょう。階段上りや徒歩など、日常的に体を動かす機会を増やすとともに、長時間運動が継続できるようになるとよいでしょう。',
    2: '持久力を高めるために、できることを考えてみましょう。まずはきつくなったら歩く、回復したら走るのを繰り返して３０分程度の継続を目標とし、慣れてきたら歩く距離を少なくしながら３０分のジョギングができるようになりましょう。階段上りや徒歩など、日常的に体を動かす機会を増やすとともに、長時間運動が継続できるようになるとよいでしょう。',
    3: '持久力を伸ばすために、トレーニングを考えてみましょう。３０分程度のジョギングを目標とし、慣れてきたら途中で速度を上げ、きつくなったら止まらずに、ゆっくりしたジョギングに戻すといった繰り返し（インターバルトレーニング）を行うことも効果的です。持久力は、中学期に伸ばすとよい能力なので、体格の発達にあわせ、肺や毛細血管も発達させるとよいでしょう。',
    4: 'すでに高い能力が見られますので、持久力をさらに伸ばすために、トレーニングを考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら、定期的に記録を測定しましょう。目標をもって計画的なトレーニングを実施し、努力するとよいでしょう。',
    5: 'すでに高い能力が見られますので、持久力をさらに伸ばすために、トレーニングを考えてみましょう。長い距離をゆっくり長時間走ることや、スピードを上げたり下げたりしながら走るインターバルトレーニング等を行いながら、定期的に記録を測定しましょう。目標をもって計画的なトレーニングを実施し、努力するとよいでしょう。',
  },
  highSchoolComments: {
    1: '持久力を高めるために、大切なことを考えてみましょう。階段登りやウォーキングなど日常生活で体を動かす機会を増やすことが大切です。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。持久力は日常生活の疲労回復にも役立ちます。',
    2: '持久力を高めるために、大切なことを考えてみましょう。階段登りやウォーキングなど日常生活で体を動かす機会を増やすことが大切です。ジョギングとウォーキングを交互に繰り返しながら３０分程度の継続を目標とし、慣れたらジョギングで３０分間の運動ができるようになるとよいでしょう。持久力は日常生活の疲労回復にも役立ちます。',
    3: '能力を向上させるために、トレーニングを考えてみましょう。体格の変化や発達する筋肉の性質も影響があり個人差も現れますが、個人の能力はトレーニングによって向上します。長時間走やインターバルトレーニングを実施し、能力の拡大を目指すとよいでしょう。',
    4: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
    5: 'さらに能力を高めるために、トレーニングを考えてみましょう。高校期になると発達する筋繊維に個人差が現れますが、まずは長時間走やインターバルトレーニング、シャトルランなどを定期的に実施することで現状把握をするとよいでしょう。目標を明確に設定した継続的なトレーニングを実践することが大切です。',
  },
}

export const ShuttleRun = {
  computeValue: (shuttleRun: number) => {
    return Math.floor(shuttleRun)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = ShuttleRun.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = ShuttleRun.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = ShuttleRun.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = ShuttleRun.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = ShuttleRun.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = ShuttleRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = ShuttleRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = ShuttleRun.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = ShuttleRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = ShuttleRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [80, 1000] },
      9: { Range: [69, 79] },
      8: { Range: [57, 68] },
      7: { Range: [45, 56] },
      6: { Range: [33, 44] },
      5: { Range: [23, 32] },
      4: { Range: [15, 22] },
      3: { Range: [10, 14] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
    FEMALE: {
      10: { Range: [64, 1000] },
      9: { Range: [54, 63] },
      8: { Range: [44, 53] },
      7: { Range: [35, 43] },
      6: { Range: [26, 34] },
      5: { Range: [19, 25] },
      4: { Range: [14, 18] },
      3: { Range: [10, 13] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [125, 1000] },
      9: { Range: [113, 124] },
      8: { Range: [102, 112] },
      7: { Range: [90, 101] },
      6: { Range: [76, 89] },
      5: { Range: [63, 75] },
      4: { Range: [51, 62] },
      3: { Range: [37, 50] },
      2: { Range: [26, 36] },
      1: { Range: [1, 25] },
    },
    FEMALE: {
      10: { Range: [88, 1000] },
      9: { Range: [76, 87] },
      8: { Range: [64, 75] },
      7: { Range: [54, 63] },
      6: { Range: [44, 53] },
      5: { Range: [35, 43] },
      4: { Range: [27, 34] },
      3: { Range: [21, 26] },
      2: { Range: [15, 20] },
      1: { Range: [1, 14] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [36, 1000] },
        4: { Range: [26, 35] },
        3: { Range: [15, 25] },
        2: { Range: [4, 14] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [51, 1000] },
        4: { Range: [36, 50] },
        3: { Range: [22, 35] },
        2: { Range: [8, 21] },
        1: { Range: [0, 7] },
      },
      8: {
        5: { Range: [64, 1000] },
        4: { Range: [47, 63] },
        3: { Range: [29, 46] },
        2: { Range: [12, 28] },
        1: { Range: [0, 11] },
      },
      9: {
        5: { Range: [75, 1000] },
        4: { Range: [56, 74] },
        3: { Range: [36, 55] },
        2: { Range: [16, 35] },
        1: { Range: [0, 15] },
      },
      10: {
        5: { Range: [84, 1000] },
        4: { Range: [63, 83] },
        3: { Range: [42, 62] },
        2: { Range: [21, 41] },
        1: { Range: [0, 20] },
      },
      11: {
        5: { Range: [94, 1000] },
        4: { Range: [71, 93] },
        3: { Range: [49, 70] },
        2: { Range: [26, 48] },
        1: { Range: [0, 25] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [29, 1000] },
        4: { Range: [21, 28] },
        3: { Range: [13, 20] },
        2: { Range: [5, 12] },
        1: { Range: [0, 4] },
      },
      7: {
        5: { Range: [39, 1000] },
        4: { Range: [29, 38] },
        3: { Range: [18, 28] },
        2: { Range: [7, 17] },
        1: { Range: [0, 6] },
      },
      8: {
        5: { Range: [48, 1000] },
        4: { Range: [36, 47] },
        3: { Range: [23, 35] },
        2: { Range: [10, 22] },
        1: { Range: [0, 9] },
      },
      9: {
        5: { Range: [59, 1000] },
        4: { Range: [44, 58] },
        3: { Range: [28, 43] },
        2: { Range: [13, 27] },
        1: { Range: [0, 12] },
      },
      10: {
        5: { Range: [67, 1000] },
        4: { Range: [51, 66] },
        3: { Range: [34, 50] },
        2: { Range: [18, 33] },
        1: { Range: [0, 17] },
      },
      11: {
        5: { Range: [74, 1000] },
        4: { Range: [57, 73] },
        3: { Range: [39, 56] },
        2: { Range: [21, 38] },
        1: { Range: [0, 20] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [107, 1000] },
        4: { Range: [83, 106] },
        3: { Range: [59, 82] },
        2: { Range: [34, 58] },
        1: { Range: [0, 33] },
      },
      13: {
        5: { Range: [124, 1000] },
        4: { Range: [99, 123] },
        3: { Range: [74, 98] },
        2: { Range: [48, 73] },
        1: { Range: [0, 47] },
      },
      14: {
        5: { Range: [132, 1000] },
        4: { Range: [106, 131] },
        3: { Range: [81, 105] },
        2: { Range: [55, 80] },
        1: { Range: [0, 54] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [82, 1000] },
        4: { Range: [63, 81] },
        3: { Range: [44, 62] },
        2: { Range: [25, 43] },
        1: { Range: [0, 24] },
      },
      13: {
        5: { Range: [91, 1000] },
        4: { Range: [70, 90] },
        3: { Range: [49, 69] },
        2: { Range: [27, 48] },
        1: { Range: [0, 26] },
      },
      14: {
        5: { Range: [92, 1000] },
        4: { Range: [70, 91] },
        3: { Range: [49, 69] },
        2: { Range: [27, 48] },
        1: { Range: [0, 26] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [123, 1000] },
        4: { Range: [100, 122] },
        3: { Range: [77, 99] },
        2: { Range: [53, 76] },
        1: { Range: [0, 52] },
      },
      16: {
        5: { Range: [131, 1000] },
        4: { Range: [106, 130] },
        3: { Range: [80, 105] },
        2: { Range: [55, 79] },
        1: { Range: [0, 54] },
      },
      17: {
        5: { Range: [131, 1000] },
        4: { Range: [105, 130] },
        3: { Range: [80, 104] },
        2: { Range: [54, 79] },
        1: { Range: [0, 53] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [79, 1000] },
        4: { Range: [62, 78] },
        3: { Range: [45, 61] },
        2: { Range: [28, 44] },
        1: { Range: [0, 27] },
      },
      16: {
        5: { Range: [84, 1000] },
        4: { Range: [65, 83] },
        3: { Range: [46, 64] },
        2: { Range: [27, 45] },
        1: { Range: [0, 26] },
      },
      17: {
        5: { Range: [82, 1000] },
        4: { Range: [63, 81] },
        3: { Range: [44, 62] },
        2: { Range: [25, 43] },
        1: { Range: [0, 24] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'おんがくに合わせながら、はじめは、かるくうでをふり、ひざはあまりたかくあげずにゆっくりはしりはじめます。かいすうがふえていくたびに、少しずつうごきを大きくしていきましょう。はやくなってくるとおりかえしのUターンがむずかしくなります。５ｍくらいまえからすこしずつスピードをおとしてあしのはばをちいさくしていくのがコツです。',
    4: 'おんがくに合わせながら、はじめは、かるくうでをふり、ひざはあまりたかくあげずにゆっくりはしりはじめます。かいすうがふえていくたびに、少しずつうごきを大きくしていきましょう。はやくなってくるとおりかえしのUターンがむずかしくなります。５ｍくらいまえからすこしずつスピードをおとしてあしのはばをちいさくしていくのがコツです。',
    3: 'はじめは、あるくようにゆっくりおんがくに合わせながらはしりはじめます。音をしっかりききながら「ド｣の音でとうちゃくするようにしましょう。ピタッととめておりかえすと足がつかれてしまうので、とまらずにちいさくまわることがたいせつです。せんは大きくこえないで、ふむだけにしてもいいですね。',
    2: 'シャトルランには、わかりやすいコツがあります。はじめから、がんばりすぎないでおんがくに合わせながらゆっくりはしるようにしましょう。いそぎすぎるとからだがつかれてしまいます。おりかえすときはきゅうにとまらないで、Uターンをすると足がらくになりますよ。',
    1: 'シャトルランには、わかりやすいコツがあります。はじめから、がんばりすぎないでおんがくに合わせながらゆっくりはしるようにしましょう。いそぎすぎるとからだがつかれてしまいます。おりかえすときはきゅうにとまらないで、Uターンをすると足がらくになりますよ。',
  },
  middleSchoolComments: {
    5: 'シャトルランは2回連続で間に合わなかったら終了となります。1度間に合わなくなっても次のポイントで間に合えば、継続することが可能です。1度遅れたとしてもがんばって記録を伸ばすことができますので、あきらめないようにしましょう。長時間かかる体力テストなので前日から体調を整えて、当日の服装は、体が熱くなることを想定して着用するといいですね。',
    4: 'シャトルランは2回連続で間に合わなかったら終了となります。1度間に合わなくなっても次のポイントで間に合えば、継続することが可能です。1度遅れたとしてもがんばって記録を伸ばすことができますので、あきらめないようにしましょう。長時間かかる体力テストなので前日から体調を整えて、当日の服装は、体が熱くなることを想定して着用するといいですね。',
    3: '記録を伸ばすには、ペース配分が大切です。レベル1（1回から7回）は、50ｍ走に例えると22.5秒のペースになりますので、周りのペースに流されず体力を温存しましょう。レベル３になっても50ｍ走で18.9秒のペースです。レベル6まで進むと50回達成できますので目標を持って行いましょう。',
    2: 'シャトルランは持久力と粘り強さが求められます。最初はゆっくりと音が流れますから、その音を聞きながらゆっくり進むようにしましょう。何回も折り返しますがラインは踏むだけで良いので、小さくUターンをするイメージを持つとスムーズになり、記録アップにつながります。',
    1: 'シャトルランは持久力と粘り強さが求められます。最初はゆっくりと音が流れますから、その音を聞きながらゆっくり進むようにしましょう。何回も折り返しますがラインは踏むだけで良いので、小さくUターンをするイメージを持つとスムーズになり、記録アップにつながります。',
  },
  highSchoolComments: {
    5: 'シャトルランは2回連続で間に合わなかったら終了となります。1度間に合わなくなっても次のポイントで間に合えば、継続することが可能です。1度遅れたとしてもがんばって記録を伸ばすことができますので、あきらめないようにしましょう。長時間かかる体力テストなので前日から体調を整えて、当日の服装は、体が熱くなることを想定して着用するといいですね。',
    4: 'シャトルランは2回連続で間に合わなかったら終了となります。1度間に合わなくなっても次のポイントで間に合えば、継続することが可能です。1度遅れたとしてもがんばって記録を伸ばすことができますので、あきらめないようにしましょう。長時間かかる体力テストなので前日から体調を整えて、当日の服装は、体が熱くなることを想定して着用するといいですね。',
    3: '記録を伸ばすには、ペース配分が大切です。レベル1（1回から7回）は、50ｍ走に例えると22.5秒のペースになりますので、周りのペースに流されず体力を温存しましょう。レベル３になっても50ｍ走で18.9秒のペースです。レベル6まで進むと50回達成できますので目標を持って行いましょう。',
    2: 'シャトルランは持久力と粘り強さが求められます。最初はゆっくりと音が流れますから、その音を聞きながらゆっくり進むようにしましょう。何回も折り返しますがラインは踏むだけで良いので、小さくUターンをするイメージを持つとスムーズになり、記録アップにつながります。',
    1: 'シャトルランは持久力と粘り強さが求められます。最初はゆっくりと音が流れますから、その音を聞きながらゆっくり進むようにしましょう。何回も折り返しますがラインは踏むだけで良いので、小さくUターンをするイメージを持つとスムーズになり、記録アップにつながります。',
  },
}

export const SprintRun = {
  computeValue: (time: number) => {
    return Math.ceil(time * 10) / 10
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = SprintRun.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SprintRun.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = SprintRun.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = SprintRun.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value < ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = SprintRun.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = SprintRun.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = SprintRun.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = SprintRun.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = SprintRun.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = SprintRun.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      1: { Range: [13.1, 1000] },
      2: { Range: [12.3, 13.1] },
      3: { Range: [11.5, 12.3] },
      4: { Range: [10.7, 11.5] },
      5: { Range: [10.0, 10.7] },
      6: { Range: [9.4, 10.0] },
      7: { Range: [8.9, 9.4] },
      8: { Range: [8.5, 8.9] },
      9: { Range: [8.1, 8.5] },
      10: { Range: [0.1, 8.1] },
    },
    FEMALE: {
      1: { Range: [13.3, 1000] },
      2: { Range: [12.5, 13.3] },
      3: { Range: [11.7, 12.5] },
      4: { Range: [11.0, 11.7] },
      5: { Range: [10.3, 11.0] },
      6: { Range: [9.7, 10.3] },
      7: { Range: [9.2, 9.7] },
      8: { Range: [8.8, 9.2] },
      9: { Range: [8.4, 8.8] },
      10: { Range: [0.1, 8.4] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      1: { Range: [9.8, 1000] },
      2: { Range: [9.1, 9.8] },
      3: { Range: [8.5, 9.1] },
      4: { Range: [8.0, 8.5] },
      5: { Range: [7.6, 8.0] },
      6: { Range: [7.3, 7.6] },
      7: { Range: [7.1, 7.3] },
      8: { Range: [6.9, 7.1] },
      9: { Range: [6.7, 6.9] },
      10: { Range: [0.1, 6.7] },
    },
    FEMALE: {
      1: { Range: [11.3, 1000] },
      2: { Range: [10.4, 11.3] },
      3: { Range: [9.9, 10.4] },
      4: { Range: [9.4, 9.9] },
      5: { Range: [9.0, 9.4] },
      6: { Range: [8.7, 9.0] },
      7: { Range: [8.4, 8.7] },
      8: { Range: [8.1, 8.4] },
      9: { Range: [7.8, 8.1] },
      10: { Range: [0.1, 7.8] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        1: { Range: [13.8, 1000] },
        2: { Range: [12.4, 13.7] },
        3: { Range: [11.0, 12.3] },
        4: { Range: [9.7, 10.9] },
        5: { Range: [0.1, 9.6] },
      },
      7: {
        1: { Range: [12.8, 1000] },
        2: { Range: [11.5, 12.7] },
        3: { Range: [10.2, 11.4] },
        4: { Range: [9.0, 10.1] },
        5: { Range: [0.1, 8.9] },
      },
      8: {
        1: { Range: [12.1, 1000] },
        2: { Range: [10.9, 12.0] },
        3: { Range: [9.7, 10.8] },
        4: { Range: [8.5, 9.6] },
        5: { Range: [0.1, 8.4] },
      },
      9: {
        1: { Range: [11.5, 1000] },
        2: { Range: [10.4, 11.4] },
        3: { Range: [9.3, 10.3] },
        4: { Range: [8.2, 9.2] },
        5: { Range: [0.1, 8.1] },
      },
      10: {
        1: { Range: [11.2, 1000] },
        2: { Range: [10.1, 11.1] },
        3: { Range: [8.9, 10.0] },
        4: { Range: [7.8, 8.8] },
        5: { Range: [0.1, 7.7] },
      },
      11: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.6, 10.6] },
        3: { Range: [8.6, 9.5] },
        4: { Range: [7.5, 8.5] },
        5: { Range: [0.1, 7.4] },
      },
    },
    FEMALE: {
      6: {
        1: { Range: [13.9, 1000] },
        2: { Range: [12.7, 13.8] },
        3: { Range: [11.5, 12.6] },
        4: { Range: [10.3, 11.4] },
        5: { Range: [0.1, 10.2] },
      },
      7: {
        1: { Range: [12.8, 1000] },
        2: { Range: [11.7, 12.7] },
        3: { Range: [10.7, 11.6] },
        4: { Range: [9.6, 10.6] },
        5: { Range: [0.1, 9.5] },
      },
      8: {
        1: { Range: [12.1, 1000] },
        2: { Range: [11.1, 12.0] },
        3: { Range: [10.1, 11.0] },
        4: { Range: [9.2, 10.0] },
        5: { Range: [0.1, 9.1] },
      },
      9: {
        1: { Range: [11.6, 1000] },
        2: { Range: [10.6, 11.5] },
        3: { Range: [9.7, 10.5] },
        4: { Range: [8.7, 9.6] },
        5: { Range: [0.1, 8.6] },
      },
      10: {
        1: { Range: [11.0, 1000] },
        2: { Range: [10.2, 10.9] },
        3: { Range: [9.3, 10.1] },
        4: { Range: [8.4, 9.2] },
        5: { Range: [0.1, 8.3] },
      },
      11: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.8, 10.6] },
        3: { Range: [8.9, 9.7] },
        4: { Range: [8.1, 8.8] },
        5: { Range: [0.1, 8.0] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.1, 10.1] },
        3: { Range: [8.1, 9.0] },
        4: { Range: [7.1, 8.0] },
        5: { Range: [0.1, 7.0] },
      },
      13: {
        1: { Range: [9.4, 1000] },
        2: { Range: [8.5, 9.3] },
        3: { Range: [7.5, 8.4] },
        4: { Range: [6.6, 7.4] },
        5: { Range: [0.1, 6.5] },
      },
      14: {
        1: { Range: [8.9, 1000] },
        2: { Range: [8.0, 8.8] },
        3: { Range: [7.2, 7.9] },
        4: { Range: [6.3, 7.1] },
        5: { Range: [0.1, 6.2] },
      },
    },
    FEMALE: {
      12: {
        1: { Range: [10.5, 1000] },
        2: { Range: [9.6, 10.4] },
        3: { Range: [8.7, 9.5] },
        4: { Range: [7.8, 8.6] },
        5: { Range: [0.1, 7.7] },
      },
      13: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.3, 10.1] },
        3: { Range: [8.5, 9.2] },
        4: { Range: [7.5, 8.4] },
        5: { Range: [0.1, 7.4] },
      },
      14: {
        1: { Range: [10.2, 1000] },
        2: { Range: [9.2, 10.1] },
        3: { Range: [8.3, 9.1] },
        4: { Range: [7.4, 8.2] },
        5: { Range: [0.1, 7.3] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        1: { Range: [8.8, 1000] },
        2: { Range: [8.1, 8.7] },
        3: { Range: [7.3, 8.0] },
        4: { Range: [6.6, 7.2] },
        5: { Range: [0.1, 6.5] },
      },
      16: {
        1: { Range: [8.7, 1000] },
        2: { Range: [7.9, 8.6] },
        3: { Range: [7.1, 7.8] },
        4: { Range: [6.4, 7.0] },
        5: { Range: [0.1, 6.3] },
      },
      17: {
        1: { Range: [8.7, 1000] },
        2: { Range: [7.9, 8.6] },
        3: { Range: [7.0, 7.8] },
        4: { Range: [6.2, 6.9] },
        5: { Range: [0.1, 6.1] },
      },
    },
    FEMALE: {
      15: {
        1: { Range: [10.4, 1000] },
        2: { Range: [9.5, 10.3] },
        3: { Range: [8.7, 9.4] },
        4: { Range: [7.8, 8.6] },
        5: { Range: [0.1, 7.7] },
      },
      16: {
        1: { Range: [10.5, 1000] },
        2: { Range: [9.6, 10.4] },
        3: { Range: [8.6, 9.5] },
        4: { Range: [7.7, 8.5] },
        5: { Range: [0.1, 7.6] },
      },
      17: {
        1: { Range: [10.7, 1000] },
        2: { Range: [9.7, 10.6] },
        3: { Range: [8.6, 9.6] },
        4: { Range: [7.6, 8.5] },
        5: { Range: [0.1, 7.5] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'きき足をうしろにしてかまえると、すばやく足をうごかしはじめられます。いいスタートダッシュをし、うでをしっかり前後にふりながらはしりましょう。りきまず、リラックスしてゴールをめざします。ゴールがおわりとかんがえると気がぬけてしまうので、ゴールよりさきをもくひょうとしましょう。',
    4: 'きき足をうしろにしてかまえると、すばやく足をうごかしはじめられます。いいスタートダッシュをし、うでをしっかり前後にふりながらはしりましょう。りきまず、リラックスしてゴールをめざします。ゴールがおわりとかんがえると気がぬけてしまうので、ゴールよりさきをもくひょうとしましょう。',
    3: 'はしるスピードをすこしずつあげるれんしゅうをしましょう。はしるフォームもたいせつです。まず、きき足をうしろにおき、かまえましょう。はしるしせいは、からだをまっすぐすいちょくにして、はしりだしたら足を左右すばやくいれかえ、じめんをけってはしりましょう。まえにすすむ力がつよくなりますよ。',
    2: '走るのがにがてな人は、あそびの中で手のふりかたや足の上げかたをれんしゅうしてみましょう。おにごっこやドッジボールがおすすめです。走る前のかまえをするときは、きき足をうしろにおき、まえにだした足にたいじゅうをのせてひざを９０どにまげておくとスタートがすばやくなります。',
    1: '走るのがにがてな人は、あそびの中で手のふりかたや足の上げかたをれんしゅうしてみましょう。おにごっこやドッジボールがおすすめです。走る前のかまえをするときは、きき足をうしろにおき、まえにだした足にたいじゅうをのせてひざを９０どにまげておくとスタートがすばやくなります。',
  },
  middleSchoolComments: {
    5: '今よりもっと走るスピードを上げていきたいですね。力まずにリラックスして走ることもコツのひとつです。走る前に手足をブラブラと振って力を抜いてから走り始めましょう。体作りも大切になってきます。たんぱく質をしっかりと取るようにしバランスの良い食事を心がけましょう。自分より少し速く走れる人と一緒に走ることも練習になります。',
    4: '今よりもっと走るスピードを上げていきたいですね。力まずにリラックスして走ることもコツのひとつです。走る前に手足をブラブラと振って力を抜いてから走り始めましょう。体作りも大切になってきます。たんぱく質をしっかりと取るようにしバランスの良い食事を心がけましょう。自分より少し速く走れる人と一緒に走ることも練習になります。',
    3: '良いスタートダッシュができたら、正しいフォームを確認しましょう。最初の５ｍ位は下を向いて走りますが、次第にまっすぐな姿勢をとります。腕を強く大きく振り、太ももは地面と平行になるくらいしっかりと上げていきましょう。足を大きく動かすことが重要です。',
    2: '速く走るために大切なのは、スタートダッシュです。利き足を後ろに引き、思い切りよく蹴って前に飛び出しましょう。低い姿勢で５ｍくらいは下を向いて走ります。太ももを上げ歩幅も大きくし、腕をしっかり前後に振りながらゴールより先を目指しましょう。',
    1: '速く走るために大切なのは、スタートダッシュです。利き足を後ろに引き、思い切りよく蹴って前に飛び出しましょう。低い姿勢で５ｍくらいは下を向いて走ります。太ももを上げ歩幅も大きくし、腕をしっかり前後に振りながらゴールより先を目指しましょう。',
  },
  highSchoolComments: {
    5: '今よりもっと走るスピードを上げていきたいですね。力まずにリラックスして走ることもコツのひとつです。走る前に手足をブラブラと振って力を抜いてから走り始めましょう。体作りも大切になってきます。たんぱく質をしっかりと取るようにしバランスの良い食事を心がけましょう。自分より少し速く走れる人と一緒に走ることも練習になります。',
    4: '今よりもっと走るスピードを上げていきたいですね。力まずにリラックスして走ることもコツのひとつです。走る前に手足をブラブラと振って力を抜いてから走り始めましょう。体作りも大切になってきます。たんぱく質をしっかりと取るようにしバランスの良い食事を心がけましょう。自分より少し速く走れる人と一緒に走ることも練習になります。',
    3: '良いスタートダッシュができたら、正しいフォームを確認しましょう。最初の５ｍ位は下を向いて走りますが、次第にまっすぐな姿勢をとります。腕を強く大きく振り、太ももは地面と平行になるくらいしっかりと上げていきましょう。足を大きく動かすことが重要です。',
    2: '速く走るために大切なのは、スタートダッシュです。利き足を後ろに引き、思い切りよく蹴って前に飛び出しましょう。低い姿勢で５ｍくらいは下を向いて走ります。太ももを上げ歩幅も大きくし、腕をしっかり前後に振りながらゴールより先を目指しましょう。',
    1: '速く走るために大切なのは、スタートダッシュです。利き足を後ろに引き、思い切りよく蹴って前に飛び出しましょう。低い姿勢で５ｍくらいは下を向いて走ります。太ももを上げ歩幅も大きくし、腕をしっかり前後に振りながらゴールより先を目指しましょう。',
  },
}

export const StandingJump = {
  computeValue: (standingJump1: number, standingJump2: number) => {
    let standingJump = Math.max(standingJump1, standingJump2)
    return Math.floor(standingJump)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = StandingJump.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = StandingJump.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = StandingJump.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = StandingJump.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = StandingJump.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = StandingJump.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = StandingJump.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = StandingJump.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = StandingJump.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = StandingJump.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [192, 1000] },
      9: { Range: [180, 191] },
      8: { Range: [168, 179] },
      7: { Range: [156, 167] },
      6: { Range: [143, 155] },
      5: { Range: [130, 142] },
      4: { Range: [117, 129] },
      3: { Range: [105, 116] },
      2: { Range: [93, 104] },
      1: { Range: [1, 92] },
    },
    FEMALE: {
      10: { Range: [181, 1000] },
      9: { Range: [170, 180] },
      8: { Range: [160, 169] },
      7: { Range: [147, 159] },
      6: { Range: [134, 146] },
      5: { Range: [121, 133] },
      4: { Range: [109, 120] },
      3: { Range: [98, 108] },
      2: { Range: [85, 97] },
      1: { Range: [1, 84] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [265, 1000] },
      9: { Range: [254, 264] },
      8: { Range: [242, 253] },
      7: { Range: [230, 241] },
      6: { Range: [218, 229] },
      5: { Range: [203, 217] },
      4: { Range: [188, 202] },
      3: { Range: [170, 187] },
      2: { Range: [150, 169] },
      1: { Range: [1, 149] },
    },
    FEMALE: {
      10: { Range: [210, 1000] },
      9: { Range: [200, 209] },
      8: { Range: [190, 199] },
      7: { Range: [179, 189] },
      6: { Range: [168, 178] },
      5: { Range: [157, 167] },
      4: { Range: [145, 156] },
      3: { Range: [132, 144] },
      2: { Range: [118, 131] },
      1: { Range: [1, 117] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [143, 1000] },
        4: { Range: [123, 142] },
        3: { Range: [104, 122] },
        2: { Range: [85, 103] },
        1: { Range: [0, 84] },
      },
      7: {
        5: { Range: [154, 1000] },
        4: { Range: [134, 153] },
        3: { Range: [114, 133] },
        2: { Range: [94, 113] },
        1: { Range: [0, 93] },
      },
      8: {
        5: { Range: [165, 1000] },
        4: { Range: [144, 164] },
        3: { Range: [124, 143] },
        2: { Range: [103, 123] },
        1: { Range: [0, 102] },
      },
      9: {
        5: { Range: [175, 1000] },
        4: { Range: [154, 174] },
        3: { Range: [133, 153] },
        2: { Range: [111, 132] },
        1: { Range: [0, 110] },
      },
      10: {
        5: { Range: [186, 1000] },
        4: { Range: [163, 185] },
        3: { Range: [140, 162] },
        2: { Range: [118, 139] },
        1: { Range: [0, 117] },
      },
      11: {
        5: { Range: [197, 1000] },
        4: { Range: [173, 196] },
        3: { Range: [150, 172] },
        2: { Range: [126, 149] },
        1: { Range: [0, 125] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [133, 1000] },
        4: { Range: [115, 132] },
        3: { Range: [97, 114] },
        2: { Range: [79, 96] },
        1: { Range: [0, 78] },
      },
      7: {
        5: { Range: [144, 1000] },
        4: { Range: [125, 143] },
        3: { Range: [107, 124] },
        2: { Range: [89, 106] },
        1: { Range: [0, 88] },
      },
      8: {
        5: { Range: [155, 1000] },
        4: { Range: [136, 154] },
        3: { Range: [116, 135] },
        2: { Range: [97, 115] },
        1: { Range: [0, 96] },
      },
      9: {
        5: { Range: [166, 1000] },
        4: { Range: [146, 165] },
        3: { Range: [126, 145] },
        2: { Range: [106, 125] },
        1: { Range: [0, 105] },
      },
      10: {
        5: { Range: [177, 1000] },
        4: { Range: [156, 176] },
        3: { Range: [145, 155] },
        2: { Range: [114, 134] },
        1: { Range: [0, 113] },
      },
      11: {
        5: { Range: [186, 1000] },
        4: { Range: [164, 185] },
        3: { Range: [142, 163] },
        2: { Range: [120, 141] },
        1: { Range: [0, 119] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [223, 1000] },
        4: { Range: [196, 222] },
        3: { Range: [169, 195] },
        2: { Range: [142, 168] },
        1: { Range: [0, 141] },
      },
      13: {
        5: { Range: [242, 1000] },
        4: { Range: [214, 241] },
        3: { Range: [185, 213] },
        2: { Range: [156, 184] },
        1: { Range: [0, 155] },
      },
      14: {
        5: { Range: [255, 1000] },
        4: { Range: [228, 254] },
        3: { Range: [200, 227] },
        2: { Range: [172, 199] },
        1: { Range: [0, 171] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [204, 1000] },
        4: { Range: [180, 203] },
        3: { Range: [156, 179] },
        2: { Range: [131, 155] },
        1: { Range: [0, 130] },
      },
      13: {
        5: { Range: [210, 1000] },
        4: { Range: [185, 209] },
        3: { Range: [159, 184] },
        2: { Range: [134, 158] },
        1: { Range: [0, 133] },
      },
      14: {
        5: { Range: [213, 1000] },
        4: { Range: [188, 212] },
        3: { Range: [163, 187] },
        2: { Range: [137, 162] },
        1: { Range: [0, 136] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [253, 1000] },
        4: { Range: [227, 252] },
        3: { Range: [202, 226] },
        2: { Range: [176, 201] },
        1: { Range: [0, 175] },
      },
      16: {
        5: { Range: [259, 1000] },
        4: { Range: [234, 258] },
        3: { Range: [208, 233] },
        2: { Range: [182, 207] },
        1: { Range: [0, 181] },
      },
      17: {
        5: { Range: [265, 1000] },
        4: { Range: [238, 264] },
        3: { Range: [210, 237] },
        2: { Range: [183, 209] },
        1: { Range: [0, 182] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [203, 1000] },
        4: { Range: [181, 202] },
        3: { Range: [158, 180] },
        2: { Range: [135, 157] },
        1: { Range: [0, 134] },
      },
      16: {
        5: { Range: [206, 1000] },
        4: { Range: [182, 205] },
        3: { Range: [158, 181] },
        2: { Range: [134, 157] },
        1: { Range: [0, 133] },
      },
      17: {
        5: { Range: [207, 1000] },
        4: { Range: [182, 206] },
        3: { Range: [157, 181] },
        2: { Range: [132, 156] },
        1: { Range: [0, 131] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'ひざをまげて、りょううではひじをのばして、しっかりぜんごにふります。きょりをのばすためには、じゅうしんはまえにおき、４５ど上ほうこうにジャンプするようにしましょう。ちゃくちのときに、うしろに手をついたりしりもちをつかないように、まえにたおれこむように、しゃがんでちゃくちしましょう。',
    4: 'ひざをまげて、りょううではひじをのばして、しっかりぜんごにふります。きょりをのばすためには、じゅうしんはまえにおき、４５ど上ほうこうにジャンプするようにしましょう。ちゃくちのときに、うしろに手をついたりしりもちをつかないように、まえにたおれこむように、しゃがんでちゃくちしましょう。',
    3: 'きろくをすこしずつあげるためのコツをつかみましょう。ジャンプにいきおいをつけると、よりとおくにとべるようになります。まずしっかり手をふり、上ほうこうではなく、ななめ４５ど上ほうこうをめざし、とおくにとぶようにしましょう。しりもちをつかないように、ちゃくちはしゃがみこむようにしましょう。',
    2: 'スキップ・なわとび・おにごっこ・うまとびなどでげんきにあそぶといろんな力がつきます。ジャンプにいきおいをつけるために、ひざをまげて、うでをふりこのように大きくふりましょう。ななめ上ほうこうにむかって、うでをふりあげるときにとび、ちゃくちはひざをかかえこむようにしっかりしゃがみこむようにしましょう。',
    1: 'スキップ・なわとび・おにごっこ・うまとびなどでげんきにあそぶといろんな力がつきます。ジャンプにいきおいをつけるために、ひざをまげて、うでをふりこのように大きくふりましょう。ななめ上ほうこうにむかって、うでをふりあげるときにとび、ちゃくちはひざをかかえこむようにしっかりしゃがみこむようにしましょう。',
  },
  middleSchoolComments: {
    5: 'できる限り遠くに飛ぶためには、まず、足元が滑らないように確認します。腕を思い切りよく大きく振りましょう。上半身と下半身のタイミングを合わせたら４５度上を目指してダイナミックにジャンプしましょう。跳躍をした後は、早めに膝を抱えるようにすると距離が伸びるようになります。',
    4: 'できる限り遠くに飛ぶためには、まず、足元が滑らないように確認します。腕を思い切りよく大きく振りましょう。上半身と下半身のタイミングを合わせたら４５度上を目指してダイナミックにジャンプしましょう。跳躍をした後は、早めに膝を抱えるようにすると距離が伸びるようになります。',
    3: '腕の大きな振り込みと脚の屈伸をタイミングよく合わせるコツは、「１，２」と号令をかけて合わせていくことです。膝の横をとおったときに１番腕の振る力が強くなるようにしましょう。斜め上方向に目印を定めると飛び出しやすくなります。着地は足を広げた状態にすると不安定さが無くなるでしょう。',
    2: '助走のない立ち幅跳びは、跳躍力を高めるために、腕と足の反動を使っていきます。足は肩幅に開き、腕を思い切りよく大きく振りましょう。腕を下ろすときに膝を９０度曲げ、上げるときに膝を伸ばします。４５度上を目指してジャンプしましょう。',
    1: '助走のない立ち幅跳びは、跳躍力を高めるために、腕と足の反動を使っていきます。足は肩幅に開き、腕を思い切りよく大きく振りましょう。腕を下ろすときに膝を９０度曲げ、上げるときに膝を伸ばします。４５度上を目指してジャンプしましょう。',
  },
  highSchoolComments: {
    5: 'できる限り遠くに飛ぶためには、まず、足元が滑らないように確認します。腕を思い切りよく大きく振りましょう。上半身と下半身のタイミングを合わせたら４５度上を目指してダイナミックにジャンプしましょう。跳躍をした後は、早めに膝を抱えるようにすると距離が伸びるようになります。',
    4: 'できる限り遠くに飛ぶためには、まず、足元が滑らないように確認します。腕を思い切りよく大きく振りましょう。上半身と下半身のタイミングを合わせたら４５度上を目指してダイナミックにジャンプしましょう。跳躍をした後は、早めに膝を抱えるようにすると距離が伸びるようになります。',
    3: '腕の大きな振り込みと脚の屈伸をタイミングよく合わせるコツは、「１，２」と号令をかけて合わせていくことです。膝の横をとおったときに１番腕の振る力が強くなるようにしましょう。斜め上方向に目印を定めると飛び出しやすくなります。着地は足を広げた状態にすると不安定さが無くなるでしょう。',
    2: '助走のない立ち幅跳びは、跳躍力を高めるために、腕と足の反動を使っていきます。足は肩幅に開き、腕を思い切りよく大きく振りましょう。腕を下ろすときに膝を９０度曲げ、上げるときに膝を伸ばします。４５度上を目指してジャンプしましょう。',
    1: '助走のない立ち幅跳びは、跳躍力を高めるために、腕と足の反動を使っていきます。足は肩幅に開き、腕を思い切りよく大きく振りましょう。腕を下ろすときに膝を９０度曲げ、上げるときに膝を伸ばします。４５度上を目指してジャンプしましょう。',
  },
}

export const HandballThrow = {
  computeValue: (handballThrow1: number, handballThrow2: number) => {
    let handballThrow = Math.max(handballThrow1, handballThrow2)
    return Math.floor(handballThrow)
  },
  computePoints: (value: number, age: number, gender: 'MALE' | 'FEMALE') => {
    let points = 0
    if (gender === 'MALE') {
      if (age < 12) {
        let ranges = HandballThrow.elementarySchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = HandballThrow.middleSchoolRanges.MALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    } else {
      if (age < 12) {
        let ranges = HandballThrow.elementarySchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      } else {
        let ranges = HandballThrow.middleSchoolRanges.FEMALE

        for (let key in ranges) {
          if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
            points = Number(key)
          }
        }
      }
    }
    return points
  },
  getRating: (value: number, age: number, gender: UserGender) => {
    let rating = 1
    age = Math.max(6, Math.min(17, age))

    if (age < 12) {
      let ranges = HandballThrow.elementarySchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else if (age < 15) {
      let ranges = HandballThrow.middleSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    } else {
      let ranges = HandballThrow.highSchoolRatings[gender][age]

      for (let key in ranges) {
        if (value >= ranges[key].Range[0] && value <= ranges[key].Range[1]) {
          rating = Number(key)
        }
      }
    }
    return rating
  },
  getComment: (rating: number, age: number) => {
    let comment = ''
    if (age < 12) {
      let ranges = HandballThrow.elementarySchoolComments
      comment = ranges[rating]
    } else if (age < 15) {
      let ranges = HandballThrow.middleSchoolComments
      comment = ranges[rating]
    } else {
      let ranges = HandballThrow.highSchoolComments
      comment = ranges[rating]
    }
    return comment
  },
  elementarySchoolRanges: {
    MALE: {
      10: { Range: [40, 1000] },
      9: { Range: [35, 39] },
      8: { Range: [30, 34] },
      7: { Range: [24, 29] },
      6: { Range: [18, 23] },
      5: { Range: [13, 17] },
      4: { Range: [10, 12] },
      3: { Range: [7, 9] },
      2: { Range: [5, 6] },
      1: { Range: [1, 4] },
    },
    FEMALE: {
      10: { Range: [25, 1000] },
      9: { Range: [21, 24] },
      8: { Range: [17, 20] },
      7: { Range: [14, 16] },
      6: { Range: [11, 13] },
      5: { Range: [8, 10] },
      4: { Range: [6, 7] },
      3: { Range: [5, 5.9] },
      2: { Range: [4, 4.9] },
      1: { Range: [1, 3] },
    },
  },
  middleSchoolRanges: {
    MALE: {
      10: { Range: [37, 1000] },
      9: { Range: [34, 36] },
      8: { Range: [31, 33] },
      7: { Range: [28, 30] },
      6: { Range: [25, 27] },
      5: { Range: [22, 24] },
      4: { Range: [19, 21] },
      3: { Range: [16, 18] },
      2: { Range: [13, 15] },
      1: { Range: [1, 12] },
    },
    FEMALE: {
      10: { Range: [23, 1000] },
      9: { Range: [20, 22] },
      8: { Range: [18, 19] },
      7: { Range: [16, 17] },
      6: { Range: [14, 15] },
      5: { Range: [12, 13] },
      4: { Range: [11, 11.9] },
      3: { Range: [10, 10.9] },
      2: { Range: [8, 9] },
      1: { Range: [1, 7] },
    },
  },
  elementarySchoolRatings: {
    MALE: {
      6: {
        5: { Range: [14, 1000] },
        4: { Range: [11, 13] },
        3: { Range: [7, 10] },
        2: { Range: [4, 6] },
        1: { Range: [0, 3] },
      },
      7: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [10, 14] },
        2: { Range: [5, 9] },
        1: { Range: [0, 4] },
      },
      8: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [13, 18] },
        2: { Range: [7, 12] },
        1: { Range: [0, 6] },
      },
      9: {
        5: { Range: [31, 1000] },
        4: { Range: [23, 30] },
        3: { Range: [16, 22] },
        2: { Range: [8, 15] },
        1: { Range: [0, 7] },
      },
      10: {
        5: { Range: [36, 1000] },
        4: { Range: [28, 35] },
        3: { Range: [19, 27] },
        2: { Range: [10, 18] },
        1: { Range: [0, 9] },
      },
      11: {
        5: { Range: [42, 1000] },
        4: { Range: [32, 41] },
        3: { Range: [22, 31] },
        2: { Range: [13, 21] },
        1: { Range: [0, 12] },
      },
    },
    FEMALE: {
      6: {
        5: { Range: [10, 1000] },
        4: { Range: [7, 9] },
        3: { Range: [5, 6] },
        2: { Range: [3, 4] },
        1: { Range: [0, 2] },
      },
      7: {
        5: { Range: [12, 1000] },
        4: { Range: [9, 11] },
        3: { Range: [7, 8] },
        2: { Range: [4, 6] },
        1: { Range: [0, 3] },
      },
      8: {
        5: { Range: [15, 1000] },
        4: { Range: [12, 14] },
        3: { Range: [8, 11] },
        2: { Range: [5, 7] },
        1: { Range: [0, 4] },
      },
      9: {
        5: { Range: [19, 1000] },
        4: { Range: [14, 18] },
        3: { Range: [10, 13] },
        2: { Range: [6, 9] },
        1: { Range: [0, 5] },
      },
      10: {
        5: { Range: [22, 1000] },
        4: { Range: [17, 21] },
        3: { Range: [12, 16] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      11: {
        5: { Range: [25, 1000] },
        4: { Range: [19, 24] },
        3: { Range: [14, 18] },
        2: { Range: [8, 13] },
        1: { Range: [0, 7] },
      },
    },
  },
  middleSchoolRatings: {
    MALE: {
      12: {
        5: { Range: [26, 1000] },
        4: { Range: [21, 25] },
        3: { Range: [16, 20] },
        2: { Range: [11, 15] },
        1: { Range: [0, 10] },
      },
      13: {
        5: { Range: [30, 1000] },
        4: { Range: [24, 29] },
        3: { Range: [18, 23] },
        2: { Range: [13, 17] },
        1: { Range: [0, 12] },
      },
      14: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
    },
    FEMALE: {
      12: {
        5: { Range: [18, 1000] },
        4: { Range: [14, 17] },
        3: { Range: [10, 13] },
        2: { Range: [6, 9] },
        1: { Range: [0, 5] },
      },
      13: {
        5: { Range: [20, 1000] },
        4: { Range: [15, 19] },
        3: { Range: [11, 14] },
        2: { Range: [7, 10] },
        1: { Range: [0, 6] },
      },
      14: {
        5: { Range: [21, 1000] },
        4: { Range: [16, 20] },
        3: { Range: [12, 15] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
    },
  },
  highSchoolRatings: {
    MALE: {
      15: {
        5: { Range: [33, 1000] },
        4: { Range: [27, 32] },
        3: { Range: [21, 26] },
        2: { Range: [15, 20] },
        1: { Range: [0, 14] },
      },
      16: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
      17: {
        5: { Range: [35, 1000] },
        4: { Range: [29, 34] },
        3: { Range: [22, 28] },
        2: { Range: [16, 21] },
        1: { Range: [0, 15] },
      },
    },
    FEMALE: {
      15: {
        5: { Range: [20, 1000] },
        4: { Range: [16, 19] },
        3: { Range: [12, 15] },
        2: { Range: [7, 11] },
        1: { Range: [0, 6] },
      },
      16: {
        5: { Range: [21, 1000] },
        4: { Range: [16, 20] },
        3: { Range: [12, 15] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
      17: {
        5: { Range: [21, 1000] },
        4: { Range: [17, 20] },
        3: { Range: [12, 16] },
        2: { Range: [8, 11] },
        1: { Range: [0, 7] },
      },
    },
  },
  elementarySchoolComments: {
    5: 'なげるはんたいの手をななめ上ほうこうにのばし、もくひょうをきめましょう。からだぜんたいをつかってなげるためには、まずうしろ足にじゅうしんをおき、まえ足をおおきくまえにふみこむときに、からだをいっきにまえむきにひねります。下むきにならないようにできるだけ高いいちからななめ上ほうこうをめざして、いきおいよくなげましょう。',
    4: 'なげるはんたいの手をななめ上ほうこうにのばし、もくひょうをきめましょう。からだぜんたいをつかってなげるためには、まずうしろ足にじゅうしんをおき、まえ足をおおきくまえにふみこむときに、からだをいっきにまえむきにひねります。下むきにならないようにできるだけ高いいちからななめ上ほうこうをめざして、いきおいよくなげましょう。',
    3: 'ボールをなげるほうこうは、ななめ４０どくらい上をめざしましょう。コツは、なげるはんたいの手をななめ４０ど上ほうこうにのばし、そのさきのもくひょうにむかってなげていくことです。なげるときには、おもいきりはんたいのてをひくようにするといきおいがつきます。手よりさきにひじをだすようにしましょう。',
    2: 'ボールをなげるまえに、まずボールをしっかりつかむことがたいせつです。ゆびとゆびのあいだをひらいて、手をひろげてもちましょう。上にむかってなげるときょりがでないので、ななめ上のほうこうをめざしてなげると、とおくにとぶようになります。からだのむきは、まっすぐまえをむかないでよこむきにかまえましょう。',
    1: 'ボールをなげるまえに、まずボールをしっかりつかむことがたいせつです。ゆびとゆびのあいだをひらいて、手をひろげてもちましょう。上にむかってなげるときょりがでないので、ななめ上のほうこうをめざしてなげると、とおくにとぶようになります。からだのむきは、まっすぐまえをむかないでよこむきにかまえましょう。',
  },
  middleSchoolComments: {
    5: '投げる能力を高めるコツは、手の力だけではなく、体全体を使って投げていくことです。体重移動がポイントになります。後ろの足に重心を乗せ、その足に溜めた力を一気に解放するように前の足を着きにいくと大きな力が生まれます。ハンドボール投げはリラックスして体の回転や体重移動を意識してしっかり行うことで、飛距離が出ます。',
    4: '投げる能力を高めるコツは、手の力だけではなく、体全体を使って投げていくことです。体重移動がポイントになります。後ろの足に重心を乗せ、その足に溜めた力を一気に解放するように前の足を着きにいくと大きな力が生まれます。ハンドボール投げはリラックスして体の回転や体重移動を意識してしっかり行うことで、飛距離が出ます。',
    3: '目標に向かってまず横向きに構えていきます。利き手と同じ側の足を後ろにし、重心を乗せていきます。前に踏み込んだときには、左足を軸に下半身を回転させます。身体から腕へ力を伝え、最後に手首のスナップを効かせて全部の力を指先からボールに伝えていきましょう。',
    2: 'ハンドボールはソフトボールよりも大きく、握り慣れていないため苦手意識が出ることがあります。手を大きく開いて、ボールをつかむことができる人は、大きく後ろに引いて構えられますが、つかめない人は、ボールを手のひらに乗せて顔の横に持ち、少し引きながらスタートしましょう。',
    1: 'ハンドボールはソフトボールよりも大きく、握り慣れていないため苦手意識が出ることがあります。手を大きく開いて、ボールをつかむことができる人は、大きく後ろに引いて構えられますが、つかめない人は、ボールを手のひらに乗せて顔の横に持ち、少し引きながらスタートしましょう。',
  },
  highSchoolComments: {
    5: '投げる能力を高めるコツは、手の力だけではなく、体全体を使って投げていくことです。体重移動がポイントになります。後ろの足に重心を乗せ、その足に溜めた力を一気に解放するように前の足を着きにいくと大きな力が生まれます。ハンドボール投げはリラックスして体の回転や体重移動を意識してしっかり行うことで、飛距離が出ます。',
    4: '投げる能力を高めるコツは、手の力だけではなく、体全体を使って投げていくことです。体重移動がポイントになります。後ろの足に重心を乗せ、その足に溜めた力を一気に解放するように前の足を着きにいくと大きな力が生まれます。ハンドボール投げはリラックスして体の回転や体重移動を意識してしっかり行うことで、飛距離が出ます。',
    3: '目標に向かってまず横向きに構えていきます。利き手と同じ側の足を後ろにし、重心を乗せていきます。前に踏み込んだときには、左足を軸に下半身を回転させます。身体から腕へ力を伝え、最後に手首のスナップを効かせて全部の力を指先からボールに伝えていきましょう。',
    2: 'ハンドボールはソフトボールよりも大きく、握り慣れていないため苦手意識が出ることがあります。手を大きく開いて、ボールをつかむことができる人は、大きく後ろに引いて構えられますが、つかめない人は、ボールを手のひらに乗せて顔の横に持ち、少し引きながらスタートしましょう。',
    1: 'ハンドボールはソフトボールよりも大きく、握り慣れていないため苦手意識が出ることがあります。手を大きく開いて、ボールをつかむことができる人は、大きく後ろに引いて構えられますが、つかめない人は、ボールを手のひらに乗せて顔の横に持ち、少し引きながらスタートしましょう。',
  },
}

export const FitnessTest = {
  isComplete: (result) => {
    let count = 0
    if (result.questionnaire || result.questionnaireV2) count += 1
    if (result.grip?.value > 0) count += 1
    if (result.sitUps?.value > 0) count += 1
    if (result.bending?.value > 0) count += 1
    if (result.sideJump?.value > 0) count += 1
    if (result.shuttleRun?.value > 0) count += 1
    if (result.sprintRun?.value > 0) count += 1
    if (result.standingJump?.value > 0) count += 1
    if (result.handballThrow?.value > 0) count += 1
    if (result.enduranceRun?.value > 0) count += 1

    return count >= 9
  },
  computePoints: (result) => {
    let grip = result?.grip?.points ?? 0
    let bending = result?.bending?.points ?? 0
    let handballThrow = result?.handballThrow?.points ?? 0
    let shuttleRun = result?.shuttleRun?.points
    let sideJump = result?.sideJump?.points ?? 0
    let sitUps = result?.sitUps?.points ?? 0
    let sprintRun = result?.sprintRun?.points ?? 0
    let standingJump = result?.standingJump?.points ?? 0
    let enduranceRun = result?.enduranceRun?.points

    if (!shuttleRun && !enduranceRun) {
      return (
        grip +
        bending +
        handballThrow +
        sideJump +
        sitUps +
        sprintRun +
        standingJump
      )
    }

    if (!shuttleRun) {
      return (
        grip +
        bending +
        handballThrow +
        sideJump +
        sitUps +
        sprintRun +
        standingJump +
        enduranceRun
      )
    }

    return (
      grip +
      bending +
      handballThrow +
      sideJump +
      sitUps +
      sprintRun +
      standingJump +
      shuttleRun
    )
  },
  computeGrade: (points: number, age: number) => {
    let grade = 'E'

    if (age < 6) {
      age = 6
    } else if (age > 19) {
      age = 19
    }
    let ranges = FitnessTest.ranges[age]

    for (let key in ranges) {
      if (points >= ranges[key].Range[0] && points <= ranges[key].Range[1]) {
        grade = key
      }
    }

    return grade
  },
  computePointsUntilNextGrade: (points: number, age: number) => {
    let grade = FitnessTest.computeGrade(points, age)
    let range = FitnessTest.ranges[age][grade].Range
    let top = range[1]
    return top - points + 1
  },
  ranges: {
    6: {
      A: { Range: [39, 100] },
      B: { Range: [33, 38] },
      C: { Range: [27, 32] },
      D: { Range: [22, 26] },
      E: { Range: [0, 21] },
    },
    7: {
      A: { Range: [47, 100] },
      B: { Range: [41, 46] },
      C: { Range: [34, 40] },
      D: { Range: [27, 33] },
      E: { Range: [0, 26] },
    },
    8: {
      A: { Range: [53, 100] },
      B: { Range: [46, 52] },
      C: { Range: [39, 45] },
      D: { Range: [32, 38] },
      E: { Range: [0, 31] },
    },
    9: {
      A: { Range: [59, 100] },
      B: { Range: [52, 58] },
      C: { Range: [45, 51] },
      D: { Range: [38, 44] },
      E: { Range: [0, 37] },
    },
    10: {
      A: { Range: [65, 100] },
      B: { Range: [58, 64] },
      C: { Range: [50, 57] },
      D: { Range: [42, 49] },
      E: { Range: [0, 41] },
    },
    11: {
      A: { Range: [71, 100] },
      B: { Range: [63, 70] },
      C: { Range: [55, 62] },
      D: { Range: [46, 54] },
      E: { Range: [0, 45] },
    },
    12: {
      A: { Range: [51, 100] },
      B: { Range: [41, 50] },
      C: { Range: [32, 40] },
      D: { Range: [22, 31] },
      E: { Range: [0, 21] },
    },
    13: {
      A: { Range: [57, 100] },
      B: { Range: [47, 56] },
      C: { Range: [37, 46] },
      D: { Range: [27, 36] },
      E: { Range: [0, 26] },
    },
    14: {
      A: { Range: [60, 100] },
      B: { Range: [51, 59] },
      C: { Range: [41, 50] },
      D: { Range: [31, 40] },
      E: { Range: [0, 30] },
    },
    15: {
      A: { Range: [61, 100] },
      B: { Range: [52, 60] },
      C: { Range: [41, 51] },
      D: { Range: [31, 40] },
      E: { Range: [0, 30] },
    },
    16: {
      A: { Range: [63, 100] },
      B: { Range: [53, 62] },
      C: { Range: [42, 52] },
      D: { Range: [31, 41] },
      E: { Range: [0, 30] },
    },
    17: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
    18: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
    19: {
      A: { Range: [65, 100] },
      B: { Range: [54, 64] },
      C: { Range: [43, 53] },
      D: { Range: [31, 42] },
      E: { Range: [0, 30] },
    },
  },
}
