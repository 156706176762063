import React, { useEffect, useState } from 'react'

import {
  Anchor,
  Divider,
  Modal,
  Table,
  TabNavigation,
} from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'
import { useQuery } from '../../hooks/useQuery'
import { useHistory, useParams } from 'react-router-dom'

import { StarIcon as SolidStarIcon } from '@heroicons/react/solid'
import { StarIcon as OutlineStarIcon } from '@heroicons/react/outline'
import { schoolGrades } from '../../utils'
import { ScoreRadarChart } from '../components/RadarChart'
import useTestResult from '../../hooks/useTestResult'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'
import AlternativeCharacter from '../../assets/images/character-alt.png'
import SMark from '../../assets/images/s-mark.png'
import SCertificate from '../../assets/images/s-certificate.png'
import { Loading } from '../shared/Loading'

const basicInfoColumns: TableColumnData[] = [
  {
    key: 'name',
    label: '名前',
    value: ['name'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell className="text-xs sm:text-lg text-gray-900" value={value} />
    ),
  },
  {
    key: 'gender',
    label: '性別',
    value: ['gender'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-lg text-gray-900"
        value={value === 'MALE' ? '男性' : '女性'}
      />
    ),
  },
  {
    key: 'schoolGrade',
    label: '学年',
    value: ['age'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-lg text-gray-900"
        value={
          schoolGrades.find((item) => item.value === value)?.name.split(' ')[1]
        }
      />
    ),
  },
  {
    key: 'age',
    label: '年齢',
    value: ['age'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell className="text-xs sm:text-lg text-gray-900">
        {value}さい
      </Table.Cell>
    ),
  },
  {
    key: 'height',
    label: '身長',
    value: ['height'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-lg text-gray-900"
        value={`${value ?? '-'} cm`}
      />
    ),
  },
  {
    key: 'weight',
    label: '体重',
    value: ['weight'],
    labelHidden: true,
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-lg text-gray-900"
        value={`${value ?? '-'} kg`}
      />
    ),
  },
]

const testResultColumns: TableColumnData[] = [
  {
    key: 'name',
    label: (
      <ruby>
        種目<rt>しゅもく</rt>
      </ruby>
    ),
    value: ['name'],
    render: (value) => (
      <Table.Cell className="text-xs sm:text-xs font-medium text-gray-900">
        {valueToJSX(value)}
      </Table.Cell>
    ),
  },
  {
    key: 'result',
    label: (
      <ruby>
        結果<rt>けっか</rt>
      </ruby>
    ),
    value: ['result'],
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-base text-gray-500"
        value={value}
      />
    ),
  },
  {
    key: 'average',
    label: (
      <ruby>
        県平均<rt>けんへいきん</rt>
      </ruby>
    ),
    value: ['average'],
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-base text-gray-500"
        value={value}
      />
    ),
  },
  {
    key: 'tScore',
    label: (
      <ruby>
        Tスコア
      </ruby>
    ),
    value: ['tScore'],
    render: (value: number) => (
      <Table.Cell className="text-xs sm:text-base text-gray-500">
        <div className="w-8">{value ? value.toFixed(2) : '-'}</div>
      </Table.Cell>
    ),
  },
  {
    key: 'score',
    label: (
      <ruby>
        得点<rt>とくてん</rt>
      </ruby>
    ),
    value: ['score'],
    render: (value) => (
      <Table.Cell className="text-xs sm:text-base text-gray-500">
        <div className="w-8">{value} 点</div>
      </Table.Cell>
    ),
  },
  {
    key: 'ranking',
    label: (
      <ruby>
        県<rt>けん</rt>ランキング
      </ruby>
    ),
    value: ['ranking'],
    render: (value) => (
      <Table.Cell
        className="text-xs sm:text-base text-gray-500"
        value={value}
      />
    ),
  },
]

export const FitnessResultPage: React.FC<any> = () => {
  let { testId } = useParams<{ testId: string }>()
  let query = useQuery()
  const history = useHistory()
  const [tabs, setTabs] = useState<any[]>([])
  const tab = query.get('tab')

  const [isSRank, setIsSRank] = useState(false)
  const [showCertificate, setShowCertificate] = useState(false)

  const { testResult, loading, error } = useTestResult(testId)

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      return
    }

    if (!testResult) {
      return
    }

    let testResultTabs = testResult.test.results.map((item) => ({
      ...item,
      to: `/results/${testId}?tab=${item.key}`,
      current: false,
    }))

    if (!tab) {
      history.replace(`/results/${testId}?tab=grip`)
      let index = testResultTabs.findIndex((item) => item.key === 'grip')
      testResultTabs[index].current = true
      setTabs(testResultTabs)
      return
    } else {
      let index = testResultTabs.findIndex((item) => item.key === tab)
      testResultTabs[index].current = true
      setTabs(testResultTabs)
    }
  }, [loading, error, testResult, tab])

  useEffect(() => {
    if (!testResult) return
    let resultsCount = testResult.test.results.length
    if (resultsCount < 8) return
    let isSRank = testResult.test.results
      .map((s) => s.score)
      .reduce((prev, curr) => {
        return prev && curr >= 8
      }, true)
    setIsSRank(isSRank)
  }, [testResult])

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>
      {loading && <div className="flex justify-center">
        <Loading />
      </div>}
      {!loading && testResult?.test && <>
        <div className="flex flex-col-reverse lg:flex-row justify-center items-center space-x-0 lg:space-x-8 py-4 my-4 bg-white rounded overflow-x-auto">
          <Table data={[testResult.test]} columns={basicInfoColumns} />
          {isSRank && (
            <button
              type="button"
              className="mb-4 lg:mb-0 flex flex-col items-center space-y-1"
              onClick={() => setShowCertificate(true)}
            >
              <img className="w-14" src={SMark} />
              <div className="text-primary-400 font-bold text-center">
                <ruby>
                  認定証<rt>にんていしょう</rt>
                </ruby>
                を見る
              </div>
            </button>
          )}
        </div>

        <div className="h-1 bg-primary-400 mt-1"></div>

        <div className="flex flex-col-reverse justify-center items-center lg:space-y-0 lg:flex-row space-x-0 lg:space-x-6 mt-8">
          <div className="mt-10 py-10 lg:mt-0 w-full overflow-x-auto sm:mx-0">
            <Table
              data={testResult.test.results.map((item, index) => ({
                key: `result-${index}`,
                ...item,
              }))}
              columns={testResultColumns}
            />
          </div>

          <div className="space-y-5 flex sm:block flex-col items-center">
            <div className="w-96 h-96 bg-white rounded-lg flex items-center justify-center">
              <div className="w-96 h-72">
                {testResult && (
                  <ScoreRadarChart
                    data={testResult?.test?.results?.filter(r => r.key !== 'points')?.map((item) => ({
                      subject: item.name.split('（')[0],
                      personal: isNaN(item.score) ? 0 : item.score,
                    }))}
                  />
                )}
              </div>
            </div>
            {testResult.test.results && (
              <>
                <div className="mb-4 w-3/4 sm:min-w-full">
                  <dl className="grid gap-5 grid-cols-1 sm:grid-cols-2">
                    {[
                      {
                        key: 'grade',
                        ...testResult.test,
                      },
                      {
                        key: 'points',
                        ...testResult.test,
                      },
                    ].map((item, index) => (
                      <div
                        key={item.key}
                        className="px-2 sm:px-4 py-2 sm:py-5 bg-primary-400 shadow rounded-lg overflow-hidden sm:p-6 text-center sm:text-left"
                      >
                        <dt className="text-sm font-bold text-white truncate">
                          {item.key === 'points' ? (
                            <span>
                              <ruby>
                                体力合計点<rt>たいりょくごうけいてん</rt>
                              </ruby>
                            </span>
                          ) : (
                            <span>
                              <ruby>
                                総合評価<rt>そうごうひょうか</rt>
                              </ruby>
                            </span>
                          )}
                        </dt>
                        <dd className="mt-1 text-3xl font-bold text-white">
                          {item.key === 'points' ? item.points : item.grade}
                        </dd>
                      </div>
                    ))}
                  </dl>
                </div>
                {testResult.test.grade && testResult.test.nextGrade && (
                  <div>
                    <span className="text-center text-xs">
                      {testResult.test.nextGrade}
                      <ruby>
                        評価<rt>ひょうか</rt>
                      </ruby>
                      (１
                      <ruby>
                        段階<rt>だんかい</rt>
                      </ruby>
                      <ruby>
                        上<rt>うえ</rt>
                      </ruby>
                      )まであと
                      {testResult.test.pointsUntilNextGrade}
                      点！
                    </span>
                  </div>
                )}
                {!testResult.test.grade && (
                  <div>
                    <span className="text-center text-xs">
                    ※総合評価は８種目全て実施した場合に表示されます。
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>

        <div className="mt-8 text-xs text-gray-500 text-center md:text-left">
          ※ランキングの
          <ruby>
            母数<rt>ぼすう</rt>
          </ruby>
          は2023
          <ruby>
            年度<rt>ねんど</rt>
          </ruby>
          の
          <ruby>
            栃木県<rt>とちぎけん</rt>
          </ruby>
          の
          <ruby>
            実数値<rt>じっすうち</rt>
          </ruby>
          です。（毎年11月更新予定）
        </div>

        <div className="h-1 bg-primary-400 mt-1"></div>

        <div className="mt-8 flex flex-col items-center">
          {tabs.length && (
            <TabNavigation
              tabs={tabs.map((tab) => ({
                ...tab,
                name: tab.name.split('（')[0],
              }))}
              type={TabNavigation.type.pills}
              onTabSelected={(tab) => {
                console.log('Tab Selected', tab)
                tab.to && history.push(tab.to)
              }}
              tabItemSelectedClassNames="bg-primary-400 font-bold"
              tabItemClassNames="font-bold text-primary-400"
            />
          )}

          {tabs.length > 0 && (
            <div className="bg-primary-400 w-full max-w-4xl rounded-lg mt-4 mb-8">
              <div className=" px-2 sm:px-4 py-2 sm:py-4">
                <div className="flex flex-col sm:flex-row justify-between">
                  <h2 className="text-lg sm:text-2xl tracking-widest font-bold text-white">
                    {valueToJSX(tabs.find((tab) => tab.current)?.name)}
                  </h2>
                  <div className="flex">
                    {Array.from(
                      Array(tabs.find((tab) => tab.current)?.rating ?? 0),
                    ).map((_, index) => (
                      <SolidStarIcon
                        className="w-4 h-4 sm:w-7 sm:h-7 text-white"
                        key={`star-${index}`}
                      />
                    ))}
                    {Array.from(
                      Array(5 - (tabs.find((tab) => tab.current)?.rating ?? 0)),
                    ).map((_, index) => (
                      <OutlineStarIcon
                        className="w-4 h-4 sm:w-7 sm:h-7 text-white"
                        key={`star-outline-${index}`}
                      />
                    ))}
                  </div>
                </div>
                <p className="text-base tracking-wide mt-4 text-white">
                  {tabs.find((tab) => tab.current)?.comment}
                </p>

                <div className="flex flex-row justify-center items-center py-8">
                  <img
                    className="w-16 mb-4 sm:w-16 z-10 mr-2 mt-12"
                    src={AlternativeCharacter}
                    alt="とちぎくん"
                  />
                  <div className="flex flex-row items-center">
                    <div
                      style={{
                        width: 0,
                        height: 0,
                        borderTop: '10px solid transparent',
                        borderRight: '20px solid rgb(254, 249, 195)',
                        borderBottom: '10px solid transparent',
                      }}
                    ></div>
                    <div className="flex flex-col items-center bg-primary-100 px-6 py-3 rounded-lg">
                      <div>
                        <span className="text-center text-base text-primary-700">
                          <ruby>
                            種目別<rt>しゅもくべつ</rt>
                          </ruby>
                          のコメントを
                          <ruby>
                            参考<rt>さんこう</rt>
                          </ruby>
                          に
                          <ruby>
                            動画<rt>どうが</rt>
                          </ruby>
                          をみながら
                          <ruby>
                            運動<rt>うんどう</rt>
                          </ruby>
                          をやってみるまる〜！
                        </span>
                      </div>
                      <Anchor
                        className="text-primary-700 text-base underline pt-1"
                        href={videoLinkFor(
                          tabs.find((tab) => tab.current)?.key,
                          tabs.find((tab) => tab.current)?.rating,
                        )}
                      >
                        体力アップ動画はこちら
                      </Anchor>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <Modal show={showCertificate} setShow={setShowCertificate}>
          <div className="relative">
            <img className="w-80 sm:w-full" src={SCertificate} />
            <div className="absolute z-50 top-40 pt-2 sm:pt-0 sm:top-52 right-0 left-0 text-lg sm:text-2xl font-bold">
              { testResult.test.name }
            </div>
          </div>
        </Modal>
      </>}
    </TochigiLayout>
  )
}

function videoLinkFor(sport: string, rating: number) {
  let type = ''
  let level = ''

  type =
    sport === 'grip'
      ? 'akuryoku'
      : sport === 'sitUps'
      ? 'joutaiokoshi'
      : sport === 'bending'
      ? 'chouzataizenkutsu'
      : sport === 'shuttleRun'
      ? '20mshuttlerun'
      : sport === 'sprintRun'
      ? '50msou'
      : sport === 'sideJump'
      ? 'hanpukuyokotobi'
      : sport === 'standingJump'
      ? 'tachihabatobi'
      : 'ballnage'

  level =
    rating === 1
      ? 'syokyuu'
      : rating === 2
      ? 'chuukyuu'
      : rating === 3
      ? 'chuukyuu'
      : rating === 4
      ? 'joukyuu'
      : rating === 5
      ? 'joukyuu'
      : 'all'

  let baseURL = 'https://www.tochigi-rising-kids.jp/movies'
  return `${baseURL}?type=${type}&level=${level}`
}

function valueToJSX(value: string) {
  return value === '握力（あくりょく）' ? (
    <span>
      <ruby>
        握力<rt>あくりょく</rt>
      </ruby>
    </span>
  ) : value === '上体起こし（じょうたいおこし）' ? (
    <span>
      <ruby>
        上体起<rt>じょうたいお</rt>こし
      </ruby>
    </span>
  ) : value === '長座体前屈（ちょうざたいぜんくつ）' ? (
    <span>
      <ruby>
        長座体前屈<rt>ちょうざたいぜんくつ</rt>
      </ruby>
    </span>
  ) : value === '反復横とび（はんぷくよことび）' ? (
    <span>
      <ruby>
        反復横<rt>はんぷくよこ</rt>とび
      </ruby>
    </span>
  ) : value === '50m走（50mそう）' ? (
    <span>
      50m
      <ruby>
        走<rt>そう</rt>
      </ruby>
    </span>
  ) : value === '立ち幅とび（たちはばとび）' ? (
    <span>
      <ruby>
        立<rt>た</rt>ち
      </ruby>
      <ruby>
        幅<rt>はば</rt>
      </ruby>
      とび
    </span>
  ) : value === 'ボール投げ（ボールなげ）' ? (
    <span>
      ボール
      <ruby>
        投<rt>な</rt>げ
      </ruby>
    </span>
  ) : (
    value
  )
}
