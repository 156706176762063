export const TochigiResults = [
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc19',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 1,
    age: 6,
    gender: 1,
    height_avg: 116.47,
    weight_avg: 21.8,
    grip_avg: 8.76,
    sit_ups_avg: 11.15,
    bending_avg: 25.06,
    side_jump_avg: 27.03,
    shuttle_run_avg: 18.8,
    sprint_run_avg: 11.79,
    standing_jump_avg: 111.92,
    ball_throw_avg: 7.49,
    score_avg: 29.11,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1a',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 2,
    age: 7,
    gender: 1,
    height_avg: 122.55,
    weight_avg: 24.81,
    grip_avg: 10.37,
    sit_ups_avg: 13.48,
    bending_avg: 26.69,
    side_jump_avg: 30.56,
    shuttle_run_avg: 26.65,
    sprint_run_avg: 10.91,
    standing_jump_avg: 123.15,
    ball_throw_avg: 10.37,
    score_avg: 35.68,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1b',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 3,
    age: 8,
    gender: 1,
    height_avg: 128.19,
    weight_avg: 28.1,
    grip_avg: 12.09,
    sit_ups_avg: 15.53,
    bending_avg: 28.16,
    side_jump_avg: 34.14,
    shuttle_run_avg: 33.29,
    sprint_run_avg: 10.37,
    standing_jump_avg: 133.57,
    ball_throw_avg: 13.55,
    score_avg: 41.39,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1c',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 4,
    age: 9,
    gender: 1,
    height_avg: 133.66,
    weight_avg: 31.85,
    grip_avg: 13.94,
    sit_ups_avg: 17.12,
    bending_avg: 30,
    side_jump_avg: 38.01,
    shuttle_run_avg: 40.07,
    sprint_run_avg: 9.94,
    standing_jump_avg: 142.3,
    ball_throw_avg: 16.95,
    score_avg: 46.74,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1d',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 5,
    age: 10,
    gender: 1,
    height_avg: 139.15,
    weight_avg: 35.68,
    grip_avg: 15.98,
    sit_ups_avg: 18.6,
    bending_avg: 32.19,
    side_jump_avg: 41.39,
    shuttle_run_avg: 47.12,
    sprint_run_avg: 9.55,
    standing_jump_avg: 149.99,
    ball_throw_avg: 20.04,
    score_avg: 51.77,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1e',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 6,
    age: 11,
    gender: 1,
    height_avg: 145.95,
    weight_avg: 40.48,
    grip_avg: 19.18,
    sit_ups_avg: 20.57,
    bending_avg: 34.48,
    side_jump_avg: 44.62,
    shuttle_run_avg: 55.45,
    sprint_run_avg: 9.07,
    standing_jump_avg: 161.83,
    ball_throw_avg: 23.45,
    score_avg: 57.9,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc1f',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 1,
    age: 6,
    gender: 2,
    height_avg: 115.61,
    weight_avg: 21.37,
    grip_avg: 8.31,
    sit_ups_avg: 11.29,
    bending_avg: 27.54,
    side_jump_avg: 26.43,
    shuttle_run_avg: 16.09,
    sprint_run_avg: 12.04,
    standing_jump_avg: 105.68,
    ball_throw_avg: 5.37,
    score_avg: 29.84,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc20',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 2,
    age: 7,
    gender: 2,
    height_avg: 121.59,
    weight_avg: 24.13,
    grip_avg: 9.91,
    sit_ups_avg: 13.44,
    bending_avg: 29.58,
    side_jump_avg: 30.07,
    shuttle_run_avg: 21.98,
    sprint_run_avg: 11.15,
    standing_jump_avg: 116.63,
    ball_throw_avg: 7.12,
    score_avg: 36.96,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc21',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 3,
    age: 8,
    gender: 2,
    height_avg: 127.75,
    weight_avg: 27.4,
    grip_avg: 11.59,
    sit_ups_avg: 15.24,
    bending_avg: 31.56,
    side_jump_avg: 33.2,
    shuttle_run_avg: 26.94,
    sprint_run_avg: 10.59,
    standing_jump_avg: 127.29,
    ball_throw_avg: 9.23,
    score_avg: 43.02,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc22',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 4,
    age: 9,
    gender: 2,
    height_avg: 133.95,
    weight_avg: 30.97,
    grip_avg: 13.53,
    sit_ups_avg: 16.92,
    bending_avg: 34.01,
    side_jump_avg: 36.77,
    shuttle_run_avg: 33.07,
    sprint_run_avg: 10.1,
    standing_jump_avg: 136.97,
    ball_throw_avg: 11.54,
    score_avg: 49.14,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc23',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 5,
    age: 10,
    gender: 2,
    height_avg: 140.99,
    weight_avg: 35.63,
    grip_avg: 16.1,
    sit_ups_avg: 18.13,
    bending_avg: 36.72,
    side_jump_avg: 40.23,
    shuttle_run_avg: 39.7,
    sprint_run_avg: 9.68,
    standing_jump_avg: 145.78,
    ball_throw_avg: 13.8,
    score_avg: 55.01,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc24',
    },
    school_category: 'ELEMENTARY_SCHOOL',
    grade: 6,
    age: 11,
    gender: 2,
    height_avg: 147.17,
    weight_avg: 40.47,
    grip_avg: 18.99,
    sit_ups_avg: 19.44,
    bending_avg: 39.45,
    side_jump_avg: 42.79,
    shuttle_run_avg: 45.36,
    sprint_run_avg: 9.3,
    standing_jump_avg: 154.24,
    ball_throw_avg: 15.72,
    score_avg: 60.08,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc25',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 1,
    age: 12,
    gender: 1,
    height_avg: 153.43,
    weight_avg: 45.76,
    grip_avg: 23.68,
    sit_ups_avg: 22.73,
    bending_avg: 39.4,
    side_jump_avg: 48.25,
    shuttle_run_avg: 64.23,
    sprint_run_avg: 8.72,
    standing_jump_avg: 177.02,
    ball_throw_avg: 16.63,
    score_avg: 32.36,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc26',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 2,
    age: 13,
    gender: 1,
    height_avg: 160.23,
    weight_avg: 50.54,
    grip_avg: 29.3,
    sit_ups_avg: 25.86,
    bending_avg: 43.54,
    side_jump_avg: 52.47,
    shuttle_run_avg: 80.98,
    sprint_run_avg: 8.06,
    standing_jump_avg: 197.05,
    ball_throw_avg: 19.85,
    score_avg: 41.39,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc27',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 3,
    age: 14,
    gender: 1,
    height_avg: 165.5,
    weight_avg: 55.32,
    grip_avg: 34.13,
    sit_ups_avg: 28.2,
    bending_avg: 47.78,
    side_jump_avg: 55.31,
    shuttle_run_avg: 89.51,
    sprint_run_avg: 7.65,
    standing_jump_avg: 210.39,
    ball_throw_avg: 22.73,
    score_avg: 48.43,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc28',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 1,
    age: 12,
    gender: 2,
    height_avg: 151.82,
    weight_avg: 44.9,
    grip_avg: 21.43,
    sit_ups_avg: 20.36,
    bending_avg: 42.53,
    side_jump_avg: 45.07,
    shuttle_run_avg: 49.4,
    sprint_run_avg: 9.19,
    standing_jump_avg: 162.57,
    ball_throw_avg: 11.38,
    score_avg: 43.32,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc29',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 2,
    age: 13,
    gender: 2,
    height_avg: 154.61,
    weight_avg: 48,
    grip_avg: 23.85,
    sit_ups_avg: 22.46,
    bending_avg: 45.99,
    side_jump_avg: 47.18,
    shuttle_run_avg: 56.52,
    sprint_run_avg: 8.92,
    standing_jump_avg: 170.27,
    ball_throw_avg: 12.75,
    score_avg: 49.34,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2a',
    },
    school_category: 'JUNIOR_HIGH_SCHOOL',
    grade: 3,
    age: 14,
    gender: 2,
    height_avg: 156.3,
    weight_avg: 50.5,
    grip_avg: 25.36,
    sit_ups_avg: 23.86,
    bending_avg: 47.95,
    side_jump_avg: 48.58,
    shuttle_run_avg: 57.94,
    sprint_run_avg: 8.79,
    standing_jump_avg: 173.67,
    ball_throw_avg: 13.87,
    score_avg: 52.64,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2b',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 1,
    age: 15,
    gender: 1,
    height_avg: 167.98,
    weight_avg: 58.92,
    grip_avg: 35.86,
    sit_ups_avg: 28.35,
    bending_avg: 48.62,
    side_jump_avg: 56.35,
    shuttle_run_avg: 81.98,
    sprint_run_avg: 7.54,
    standing_jump_avg: 217.69,
    ball_throw_avg: 22.82,
    score_avg: 49.26,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2c',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 2,
    age: 16,
    gender: 1,
    height_avg: 169.37,
    weight_avg: 60.68,
    grip_avg: 38.31,
    sit_ups_avg: 29.48,
    bending_avg: 50.59,
    side_jump_avg: 57.71,
    shuttle_run_avg: 89,
    sprint_run_avg: 7.39,
    standing_jump_avg: 222.93,
    ball_throw_avg: 23.81,
    score_avg: 52.56,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2d',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 3,
    age: 17,
    gender: 1,
    height_avg: 170.34,
    weight_avg: 62.68,
    grip_avg: 40.19,
    sit_ups_avg: 30.59,
    bending_avg: 53.1,
    side_jump_avg: 58.63,
    shuttle_run_avg: 88.76,
    sprint_run_avg: 7.31,
    standing_jump_avg: 227.29,
    ball_throw_avg: 25.15,
    score_avg: 55.14,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2e',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 1,
    age: 15,
    gender: 2,
    height_avg: 156.8,
    weight_avg: 51.47,
    grip_avg: 25.3,
    sit_ups_avg: 23.53,
    bending_avg: 48.92,
    side_jump_avg: 48.64,
    shuttle_run_avg: 50.74,
    sprint_run_avg: 8.97,
    standing_jump_avg: 172.17,
    ball_throw_avg: 13.27,
    score_avg: 50.78,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc2f',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 2,
    age: 16,
    gender: 2,
    height_avg: 157.45,
    weight_avg: 52.81,
    grip_avg: 26.13,
    sit_ups_avg: 24.34,
    bending_avg: 50.42,
    side_jump_avg: 49.15,
    shuttle_run_avg: 52.37,
    sprint_run_avg: 9.01,
    standing_jump_avg: 173.55,
    ball_throw_avg: 13.63,
    score_avg: 52.15,
  },
  {
    _id: {
      $oid: '61b63e1704080d0699b5fc30',
    },
    school_category: 'ALL_DAY_HIGH_SCHOOL',
    grade: 3,
    age: 17,
    gender: 2,
    height_avg: 157.73,
    weight_avg: 53.16,
    grip_avg: 26.52,
    sit_ups_avg: 24.53,
    bending_avg: 51.65,
    side_jump_avg: 49.51,
    shuttle_run_avg: 51.48,
    sprint_run_avg: 9.03,
    standing_jump_avg: 173.73,
    ball_throw_avg: 14.02,
    score_avg: 52.91,
  },
]
