import { useContext, useState } from 'react'

import { useHistory } from 'react-router'

import { Button, Input } from '@sergeimeza/uikit-react'

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { TochigiLayout } from '../layout/TochigiLayout'

import { TochigiContext } from '../../providers/TochigiProvider'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'

let loginSchema = yup
  .object({
    username: yup.string().min(4).max(100).required(),
    birthday: yup.string().required(),
    password: yup.string().min(4).max(100).required(),
    confirmPassword: yup.string().min(4).max(100).required(),
  })
  .required()

export function ChangePasswordPage() {
  const history = useHistory()
  const [showPassword, setShowPassword] = useState(false)
  const tochigiCtx = useContext(TochigiContext)

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<any>({
    defaultValues: {
      birthday: '2015-01-01',
      username: '',
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(loginSchema),
  })

  async function onLoginSubmit(data: any) {
    let { username, password, confirmPassword, birthday } = data
    if (password !== confirmPassword) {
      alert('パスワードとパスワードの確認は同じくありません。')
      return
    }
    let result = await tochigiCtx.changePassword(username, password, birthday)
    if (result) {
      tochigiCtx.resetLoginSession(null)
      history.replace('/login')
    } else {
      alert('誕生日もしくはアカウント名が違います。')
    }
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      <div className="mt-5 md:mt-0 md:col-span-2">
        <form onSubmit={handleSubmit(onLoginSubmit)}>
          <div className="border border-yellow-500 overflow-hidden sm:rounded-md">
            <div className="px-4 py-5 bg-white sm:p-6">
              <h2 className="mb-7 text-center text-2xl tracking-widest font-medium text-gray-700">
                パスワードを
                <ruby>
                  変更<rt>へんこう</rt>
                </ruby>
              </h2>

              <div className="grid grid-cols-6 gap-6">
                <div className="col-span-6 lg:col-span-4">
                  <Input
                    type="date"
                    label={
                      <>
                        <span>
                          <ruby>
                            生年月日
                            <rt>せいねんがっぴ</rt>
                          </ruby>
                        </span>
                      </>
                    }
                    placeholder=""
                    inputClassNames="bg-primary-100 border-0"
                    autoComplete="birthday"
                    description=""
                    cornerHint=""
                    register={register('birthday')}
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <Input
                    type="text"
                    label={
                      <>
                        <span>
                          アカウント
                          <ruby>
                            名<rt>めい</rt>
                          </ruby>
                        </span>
                        <span className="pl-4 text-xs">
                          ※ 4
                          <ruby>
                            文字以上
                            <rt>もじいじょう</rt>
                          </ruby>
                          <ruby>
                            空白
                            <rt>くうはく</rt>
                          </ruby>
                          なし
                        </span>
                      </>
                    }
                    description={
                      <div className="text-sm">
                        *ひらがな/カタカナ/漢字/英/数字の入力可能
                      </div>
                    }
                    inputClassNames="bg-primary-100 border-0"
                    autoComplete="username"
                    register={register('username')}
                    error={
                      errors.username?.message &&
                      '正しいアカウント名を入力してください。'
                    }
                  />
                </div>

                <div className="col-span-6 sm:col-span-4">
                  <Input.Secure
                    type={showPassword ? 'text' : 'password'}
                    label={
                      <>
                        <span>
                          <ruby>
                            新<rt>あたら</rt>
                          </ruby>
                          しいパスワード
                        </span>
                        <span className="pl-4 text-xs">
                          ※ 4
                          <ruby>
                            文字以上
                            <rt>もじいじょう</rt>
                          </ruby>
                          <ruby>
                            空白
                            <rt>くうはく</rt>
                          </ruby>
                          なし
                        </span>
                      </>
                    }
                    description={
                      <div className="text-sm">*英/数字/記号の入力可能</div>
                    }
                    autoComplete="new-password"
                    inputClassNames="bg-primary-100 border-0"
                    register={register('password')}
                    error={
                      errors.password?.message &&
                      '正しいパスワードを入力してください。'
                    }
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.Secure
                    type={showPassword ? 'text' : 'password'}
                    label={
                      <>
                        <span>
                          <ruby>
                            新<rt>あたら</rt>
                          </ruby>
                          しいパスワードの
                          <ruby>
                            確認<rt>かくにん</rt>
                          </ruby>
                        </span>
                        <span className="pl-4 text-xs">
                          ※ 4
                          <ruby>
                            文字以上
                            <rt>もじいじょう</rt>
                          </ruby>
                          <ruby>
                            空白
                            <rt>くうはく</rt>
                          </ruby>
                          なし
                        </span>
                      </>
                    }
                    autoComplete="new-password"
                    inputClassNames="bg-primary-100 border-0"
                    register={register('confirmPassword')}
                    error={
                      errors.confirmPassword?.message &&
                      '正しいパスワードを入力してください。'
                    }
                  />
                </div>
                <div className="col-span-6 sm:col-span-4">
                  <Input.Checkbox
                    label="パスワードを見る"
                    onChange={setShowPassword}
                  />
                </div>
              </div>
            </div>
            <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
              <div className="space-x-4">
                <Button
                  className="inline-flex justify-center"
                  type="submit"
                  size={Button.size.xl}
                  buttonType={
                    'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
                  }
                >
                  <div>
                    パスワードを
                    <ruby>
                      変更<rt>へんこう</rt>する
                    </ruby>
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </TochigiLayout>
  )
}
