import React, { useContext, useState } from 'react'
import { Button, Modal } from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'
import { TochigiContext } from '../../providers/TochigiProvider'
import { useHistory } from 'react-router'

export const SettingsPage: React.FC<any> = () => {
  const tochigiContext = useContext(TochigiContext)
  const history = useHistory()
  const [showModal, setShowModal] = useState(false)

  function changePassword() {
    history.push('/change-password')
  }

  async function deleteAccount() {
    await tochigiContext.deleteUser()
    tochigiContext.resetLoginSession({})
    alert('アカウントは削除されました。ホーム画面に戻ります')
    window.location.replace('https://www.tochigi-rising-kids.jp')
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      <div className="flex flex-col items-center mb-10">
        <div className="text-2xl mb-10">
          <ruby>
            設定<rt>せってい</rt>
          </ruby>
        </div>

        <div className="space-y-10">
          {/* change password menu */}
          <div className="flex flex-col items-center space-y-4　pb-10">
            <Button
              className="inline-flex justify-center border border-primary-700"
              onClick={changePassword}
              buttonType={Button.buttonType.secondary}
            >
              <div>
                パスワードを
                <ruby>
                  変更<rt>へんこう</rt>
                </ruby>
              </div>
            </Button>
          </div>

          {/* delete account menu */}
          <div className="flex flex-col items-center space-y-4 pb-10">
            <Button
              className="inline-flex justify-center border border-red-700"
              onClick={() => setShowModal(true)}
              buttonType={
                'border border-red-700 text-white font-bold bg-red-400 hover:bg-red-300' as any
              }
            >
              <div>
                アカウントを
                <ruby>
                  削除<rt>さくじょ</rt>
                </ruby>
              </div>
            </Button>
            <div className="text-sm text-center">
              ※ アカウントを
              <ruby>
                削除<rt>さくじょ</rt>
              </ruby>
              すると、すべてのデータが
              <ruby>
                消<rt>け</rt>
              </ruby>
              されます。
            </div>
          </div>
        </div>
      </div>

      {/* confirm account deletion modal */}
      <Modal show={showModal} setShow={setShowModal}>
        <Modal.Card
          icon={<Modal.AlertIcon />}
          title={
            <span>
              <ruby>
                本当<rt>ほんとう</rt>
              </ruby>
              に
              <ruby>
                削除<rt>さくじょ</rt>
              </ruby>
              しますか？
            </span>
          }
          body={
            <span>
              <ruby>
                本当<rt>ほんとう</rt>
              </ruby>
              に
              <ruby>
                削除<rt>さくじょ</rt>
              </ruby>
              しますか？すべてのデータが
              <ruby>
                削除<rt>さくじょ</rt>
              </ruby>
              されます。
            </span>
          }
          setShow={setShowModal}
          cta={
            <>
              <Button
                className="inline-flex justify-center items-center border border-primary-700"
                onClick={() => setShowModal(false)}
                buttonType={Button.buttonType.secondary}
              >
                <div>もどる</div>
              </Button>
              <Button
                className="inline-flex justify-center"
                onClick={() => deleteAccount()}
                buttonType={
                  'border border-red-700 text-white font-bold bg-red-400 hover:bg-red-300' as any
                }
              >
                <div>
                  アカウントを
                  <ruby>
                    削除<rt>さくじょ</rt>
                  </ruby>
                </div>
              </Button>
            </>
          }
        />
      </Modal>
    </TochigiLayout>
  )
}

// 本当に削除しますか？全てのデータが削除されます
