import { useAsync } from '@sergeimeza/foundation-react'
import { TochigiAPI } from '../providers/TochigiProvider'

export default function useTestResult(testId: string) {
  const { value, loading, error } = useAsync(async () => {
    let testResult = await TochigiAPI.get(`/tests/${testId}`)
    if (testResult.data) {
      let { test, leaderboard } = testResult.data.data
      test.results = test.results.map((item) => {
        if (item.score) {
          let rank =
            leaderboard.find(
              (leaderboardItem) => leaderboardItem.key === item.key,
            ).rank + 1

          let count = leaderboard.find(
            (leaderboardItem) => leaderboardItem.key === item.key,
          ).count

          const average = leaderboard.find(
            (leaderboardItem) => leaderboardItem.key === item.key,
          ).avg

          const deviation = leaderboard.find(
            (leaderboardItem) => leaderboardItem.key === item.key,
          ).dev

          const value = test[item.key] as number
          let tScore = (value - average) / deviation

          if (item.key === 'sprintRun') tScore *= -1
          tScore = tScore * 10 + 50

          rank = Math.min(Number(rank), Number(count))

          return {
            ...item,
            average,
            tScore,
            ranking: `${rank}位 / ${count}人`,
          }
        } else {
          return { ...item }
        }
      })
      return { test, leaderboard }
    } else {
      throw new Error('テスト結果を見付かれませんでした')
    }
  }, [testId])

  return { testResult: value, loading, error }
}
