import {
  RadarChart,
  Legend,
  PolarAngleAxis,
  PolarGrid,
  PolarRadiusAxis,
  Radar,
  ResponsiveContainer,
  Tooltip,
} from 'recharts'

export type ScoreData = {
  subject: string
  personal: number
}

type Props = {
  data: ScoreData[]
}

export const ScoreRadarChart = ({ data }: Props) => {
  const radarChartProps = {
    // width: 0,
    // height: 0,
    data: data,
  }
  const radarChart = (
    <RadarChart {...radarChartProps}>
      <PolarGrid />
      <PolarAngleAxis dataKey="subject" />
      <PolarRadiusAxis angle={75} tickCount={10} domain={[0, 10]} />
      <Radar
        name="個人結果"
        dataKey="personal"
        stroke="#FF0000"
        fill="#FF0000"
        fillOpacity={0.2}
        isAnimationActive={false}
      />
      <Legend />
      <Tooltip />
    </RadarChart>
  )

  return (
    <ResponsiveContainer height="100%" width="100%">
      {radarChart}
    </ResponsiveContainer>
  )
}

export default ScoreRadarChart
