import { useContext, useEffect } from 'react'

import moment from 'moment'

import { Anchor, Button, Table } from '@sergeimeza/uikit-react'
import { TableColumnData } from '@sergeimeza/uikit-react/lib/Elements/Table/Table'

import { TochigiContext } from '../../providers/TochigiProvider'

import { TochigiLayout } from '../layout/TochigiLayout'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'
import { useHistory } from 'react-router'

export function FitnessResultsPage() {
  const history = useHistory()
  const tochigiCtx = useContext(TochigiContext)

  useEffect(() => {
    tochigiCtx.signIn()
  }, [])

  function onSeeResultsClick(resultsId) {
    history.push(`/results/${resultsId}?tab=grip`)
  }

  async function onDeleteResultsClick(resultsId) {
    await tochigiCtx.deleteTestResults(resultsId)
    window.location.reload()
  }

  let testColumns: TableColumnData[] = [
    {
      key: 'testDate',
      label: '測定日',
      value: ['testDate'],
      render: (value) => <Table.Cell>{moment(value).format('ll')}</Table.Cell>,
    },
    {
      key: 'createdAt',
      label: '登録日',
      value: ['createdAt'],
      render: (value) => <Table.Cell>{moment(value).format('ll')}</Table.Cell>,
    },
    {
      key: 'actions',
      label: '',
      render: (value) => (
        <Table.Cell>
          <div className="flex flex-col sm:flex-row items-end space-y-2 sm:space-y-0 sm:space-x-4 sm:items-center">
            <Button
              onClick={() => onSeeResultsClick(value._id)}
              buttonType={
                'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
              }
            >
              <div className="text-xs sm:text-base">
                <ruby>
                  結果<rt>けっか</rt>
                </ruby>
                を見る
              </div>
            </Button>
            <Button
              onClick={() => onDeleteResultsClick(value._id)}
              buttonType={Button.buttonType.secondary}
            >
              <div className="text-xs sm:text-base">
                <ruby>
                  消<rt>け</rt>
                </ruby>
                す
              </div>
            </Button>
          </div>
        </Table.Cell>
      ),
    },
  ]

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>
      <div className="mb-6">
        {tochigiCtx.user?.relationships?.tests?.length ? (
          <Table
            data={
              tochigiCtx.user?.relationships?.tests?.map((test) => ({
                key: test._id,
                ...test,
              })) ?? []
            }
            columns={testColumns}
          />
        ) : (
          <div className="text-center text-lg text-gray-700 pt-10">
            <ruby>
              結果<rt>けっか</rt>
            </ruby>
            がまだありません。
          </div>
        )}
      </div>
    </TochigiLayout>
  )
}
