import { useContext, useEffect } from 'react'
import { useHistory } from 'react-router'

import { useAsync } from '@sergeimeza/foundation-react'
import { Button, Divider } from '@sergeimeza/uikit-react'

import { TochigiLayout } from '../layout/TochigiLayout'
import { TochigiContext } from '../../providers/TochigiProvider'

import Fitness from '../../assets/images/fitness.png'
import Character from '../../assets/images/character.png'

export function HomePage() {
  const history = useHistory()
  const tochigiCtx = useContext(TochigiContext)

  const { loading: sessionLoading } = useAsync(async () => {
    if (tochigiCtx.loginSession) {
      const { username, password, birthday } = tochigiCtx.loginSession
      const success = await tochigiCtx.linkWithUsernamePassword(
        username,
        password,
        birthday,
      )
      if (success) {
        history.replace('/fitness')
      }
    } else {
      return
    }
  }, [tochigiCtx.loginSession])

  function guestLogin() {
    history.replace('/fitness')
  }

  function createAccount() {
    history.push('/create-account')
  }

  function login() {
    history.push('/login')
  }

  if (sessionLoading) {
    return null
  }

  return (
    <TochigiLayout>
      <header className="mb-10">
        <img
          className="absolute bottom-10 left-10 w-16 sm:w-20 mx-auto z-10"
          src={Character}
          alt="とちぎくん"
        />
        <div className="flex">
          <img
            className="w-60 sm:w-72 mx-auto z-10"
            src={Fitness}
            alt="体力診断ゾーン"
          />
        </div>
      </header>

      <div className="flex flex-col items-center">
        <div className="text-center py-4 mb-4 tracking-wide text-2xl">
          <ruby>
            測定結果<rt>そくていけっか</rt>
          </ruby>
          を
          <ruby>
            入力し<rt>にゅうりょく</rt>
          </ruby>
          てみましょう
        </div>
        <Button
          onClick={guestLogin}
          size={Button.size.xl}
          buttonType={
            'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
          }
        >
          <div>
            <ruby>
              登録<rt>とうろく</rt>
            </ruby>
            をしないで
            <ruby>
              利用<rt>りよう</rt>
            </ruby>
            する
          </div>
        </Button>
        <Divider className="w-3/4 my-4" title="または" />
        <div className="space-y-4 flex flex-col items-center mb-10">
          <div className="text-center pt-4 tracking-wide text-2xl">
            <ruby>
              登録<rt>とうろく</rt>
            </ruby>
            をして
            <ruby>
              利用<rt>りよう</rt>
            </ruby>
            しましょう
          </div>

          <div className="text-center py-4 tracking-wide text-base">
            <ruby>
              登録<rt>とうろく</rt>
            </ruby>
            すると
            <ruby>
              結果<rt>けっか</rt>
            </ruby>
            が
            <ruby>
              保存<rt>ほぞん</rt>
            </ruby>
            されます！
            <br />
            ぜひ
            <ruby>
              登録<rt>とうろく</rt>
            </ruby>
            して
            <ruby>
              成長<rt>せいちょう</rt>
            </ruby>
            を見てみましょう。
          </div>

          <Button
            onClick={createAccount}
            size={Button.size.xl}
            buttonType={
              'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
            }
          >
            <div>
              アカウント
              <ruby>
                作成<rt>さくせい</rt>
              </ruby>
            </div>
          </Button>
          <Button
            onClick={login}
            size={Button.size.xl}
            buttonType={
              'border border-transparent shadow-sm text-white font-bold bg-primary-400 hover:bg-primary-300' as any
            }
          >
            <div>
              ２
              <ruby>
                回目<rt>かいめ</rt>
              </ruby>
              <ruby>
                以降<rt>いこう</rt>
              </ruby>
              はログイン
            </div>
          </Button>
        </div>
      </div>
    </TochigiLayout>
  )
}
