import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { TochigiProvider } from './providers/TochigiProvider'

import { NotFoundPage } from './components/pages/NotFoundPage'
import { FitnessPage } from './components/pages/FitnessPage'
import { FitnessResultPage } from './components/pages/FitnessResultPage'
import { HomePage } from './components/pages/HomePage'
import { CreateAccountPage } from './components/pages/CreateAccountPage'
import { LoginPage } from './components/pages/LoginPage'
import { ChangePasswordPage } from './components/pages/ChangePasswordPage'
import { SettingsPage } from './components/pages/SettingsPage'
import { FitnessResultsPage } from './components/pages/FitnessResultsPage'
import { VideosPage } from './components/pages/VideosPage'

function App() {
  return (
    <TochigiProvider>
      <Router>
        <Switch>
          {/* Test Results系 */}
          <Route path="/results/:testId">
            <FitnessResultPage />
          </Route>
          <Route exact path="/results">
            <FitnessResultsPage />
          </Route>
          <Route path="/fitness">
            <FitnessPage />
          </Route>
          <Route path="/videos">
            <VideosPage />
          </Route>
          {/* Login系 */}
          <Route path="/create-account">
            <CreateAccountPage />
          </Route>
          <Route path="/login">
            <LoginPage />
          </Route>
          <Route path="/change-password">
            <ChangePasswordPage />
          </Route>
          <Route path="/settings">
            <SettingsPage />
          </Route>
          <Route exact path="/">
            <HomePage />
          </Route>
          <Route path="/">
            <NotFoundPage />
          </Route>
        </Switch>
      </Router>
    </TochigiProvider>
  )
}

export default App
